import BaseClass from '@/utils/baseclass'
import moment from 'moment'
export default class Requests extends BaseClass {
  addItem(item) {
    let newItem = new Request(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.code = item.code
      findItem.status = item.status
      findItem.requestType = item.type
      findItem.orderStatus = item.orderStatus
      findItem.totalAmount = item.totalAmount
    }
  }
}

export class Request {
  constructor(item) {
    this.id = item.id
    this.code = item.code
    this.status = item.status
    this.orderStatus = item.requestItemOrderStatus
    this.showDetail = false
    this.showAccountingStatus = false
    this.wmsStatus = item.wmsStatus
    this.wmsMessage = item.wmsMessage
    this.detailList = []
    this.requestType = item.type
    this.description = item.description
    this.rejectionReason = item.rejectionReason
    this.modifiedOn = moment(item.modifiedOn).format('DD-MM-YYYY') + ' / ' + moment(item.modifiedOn).add(new Date().getTimezoneOffset() / -60, 'hours').format('H:mm')
    this.modifiedBy = item.modifiedBy
    this.priceType = item.priceType
    this.totalAmount = item.totalAmount
  }
  addDetails(detailList) {
    this.detailList = []
    detailList.forEach(detail => {
      this.detailList.push(new RequestDetail(detail))
    })
  }

  get isLive() {
    return this.status === 2
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id)
    if (findIndex > -1) {
      this.detailList.splice(findIndex, 1)
    }
  }

  findIndex(Id) {
    return this.detailList.findIndex(item => item.id === Id)
  }

  findItem(Id) {
    return this.detailList.find(item => item.id === Id)
  }

  get detailIsEmpty() {
    return this.detailList.length === 0
  }
}

export class RequestDetail {
  constructor(detail) {
    this.requestType = detail.type
    this.id = detail.id
    this.headerId = detail.headerId
    this.storeId = detail.storeId
    this.storeName = detail.storeName
    this.stockItemId = detail.stockItemId
    this.stockItemName = detail.stockItemName
    this.unitId = detail.unitId
    this.unitName = detail.unitName
    this.quantity = detail.quantity
    this.requestDate = detail.requestDate
    this.requestItemStatus = detail.orderStatus
    this.fromStoreName = detail.fromStoreName
    this.fromStoreId = detail.fromStoreId
    this.description = detail.description
    this.status = detail.status
    this.state = detail.state
    this.minimumStoreItemQuantity = detail.minimumStoreItemQuantity
    this.optimumStoreItemQuantity = detail.optimumStoreItemQuantity
    this.recommendedQuantity = detail.recommendedQuantity
    this.stockOnHandQuantity = detail.stockOnHandQuantity
    this.priceType = detail.priceType
    this.sourceType = detail.sourceType
    this.transferredQuantity = detail.transferredQuantity
    this.orderedQuantity = detail.orderedQuantity
    this.unitPrice = detail.unitPrice
    this.baseUnitPrice = detail.baseUnitPrice
    this.amount = (detail.quantity * detail.unitPrice).toFixed(3)
  }
}
