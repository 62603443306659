var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        !_vm.isEdit
          ? _c("div", [_vm._v(_vm._s(_vm.$t("Request.add_Request")))])
          : _c("div", [_vm._v(_vm._s(_vm.$t("Request.update_Request")))]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "div",
          [
            _c("p", [_vm._v(_vm._s(_vm.$t("Request.formFieldDescription")))]),
            _c(
              "div",
              { staticClass: "control" },
              [
                _c("customTextareaInput", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:128",
                      expression: "'max:128'",
                    },
                  ],
                  attrs: {
                    id: "request-header-form-description",
                    name: "description",
                    "data-vv-as": _vm.$t("Request.formFieldDescription"),
                    error: _vm.veeErrors.has("description"),
                    rows: "3",
                  },
                  model: {
                    value: _vm.description,
                    callback: function ($$v) {
                      _vm.description = $$v
                    },
                    expression: "description",
                  },
                }),
                _c("showValidateError", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("description"),
                      expression: "veeErrors.has('description')",
                    },
                  ],
                  attrs: { errorName: _vm.veeErrors.first("description") },
                }),
              ],
              1
            ),
            !_vm.isEdit
              ? _c("p", { staticClass: "requestFormItem" }, [
                  _vm._v(_vm._s(_vm.$t("Request.select_type"))),
                ])
              : _vm._e(),
            _vm._l(_vm.requestTypes, function (type, index) {
              return _vm.checkPermission(type.permissionKey) && !_vm.isEdit
                ? _c(
                    "Button",
                    {
                      key: type.value,
                      staticClass: "m-bottom-20 requestFormItem",
                      attrs: {
                        seashell: "",
                        variant: "full",
                        size: "large",
                        id: `form-request-header-button-${index}`,
                        disabled:
                          _vm.isCreatingData ||
                          _vm.veeErrors.has("description"),
                        isLoading:
                          _vm.isCreatingData && type.value === _vm.selectedType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.createNewRequest(type.value)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(type.text))])]
                  )
                : _vm._e()
            }),
          ],
          2
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.isEdit
            ? _c(
                "Button",
                {
                  staticClass: "item",
                  attrs: {
                    id: "btn-update-request-header",
                    primary: "",
                    size: "medium",
                    variant: "full",
                    disabled:
                      _vm.isUpdatingData || _vm.veeErrors.has("description"),
                  },
                  on: { click: _vm.editRequestHeader },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isUpdatingData,
                          expression: "!isUpdatingData",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(_vm.$t("Request.update_Request")))]
                  ),
                  _c("Loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isUpdatingData,
                        expression: "isUpdatingData",
                      },
                    ],
                    attrs: { theme: "disable" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }