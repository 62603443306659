import BaseClass from '@/utils/baseclass'

export default class DirectOrders extends BaseClass {
  addItem(item) {
    let newItem = new DirectOrder(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.code = item.code
      findItem.status = item.status
      findItem.priceQuoteTotalAmount = item.priceQuoteTotalAmount
      findItem.maxApprovalLevel = item.maxApprovalLevel
      findItem.currentApprovalLevel = item.currentApprovalLevel
      findItem.approvalStatus = item.approvalStatus
      findItem.headerType = item.headerType
      findItem.description = item.description
    }
  }
}

export class DirectOrder {
  constructor(item) {
    this.id = item.id
    this.code = item.code
    this.status = item.status
    this.showDetail = false
    this.detailList = []
    this.priceQuoteTotalAmount = item.priceQuoteTotalAmount
    this.totalUnitQuantityList = item.totalUnitQuantityList || []
    this.maxApprovalLevel = item.maxApprovalLevel || 0
    this.currentApprovalLevel = item.currentApprovalLevel || 0
    this.approvalStatus = item.approvalStatus || 0
    this.headerType = item.headerType
    this.description = item.description
  }

  updateTotalPrice() {
    const isEmptyHeaderTotalPrice = this.detailList.some(item => item.priceQuoteTotalUnitPrice !== null)
    if (!isEmptyHeaderTotalPrice) {
      this.priceQuoteTotalAmount = null
      return
    }
    const total = this.detailList.reduce((acc, { priceQuoteTotalUnitPrice }) => {
      return acc + priceQuoteTotalUnitPrice
    }, 0)
    this.priceQuoteTotalAmount = total
  }

  findDetail(detailId) {
    return this.detailList.find(detail => detail.id === detailId)
  }

  addDetails(detailList) {
    this.detailList = []
    detailList.forEach(detail => {
      this.detailList.push(new DirectOrderDetail({detail, approvalStatus: this.approvalStatus, status: this.status}))
    })
  }
}

export class DirectOrderDetail {
  constructor({detail, approvalStatus, status}) {
    this.id = detail.id
    this.headerId = detail.headerId
    this.code = detail.code
    this.storeId = detail.storeId
    this.storeName = detail.storeName
    this.stockItemId = detail.stockItemId
    this.stockItemName = detail.stockItemName
    this.supplierId = detail.supplierId
    this.supplierName = detail.supplierName
    this.unitId = detail.unitId
    this.unitName = detail.unitName
    this.quantity = detail.quantity
    this.requestDate = detail.requestDate
    this.priceQuoteUnitPrice = detail.priceQuoteUnitPrice
    this.isQuantityRequired = detail.isQuantityRequired
    this.isZeroPriceAvailableForSupplier = detail.isZeroPriceAvailableForSupplier
    this.description = detail.description
    this.approvalStatus = approvalStatus
    this.status = status
    this.headerType = detail.headerType
    this.stockOnHandQuantity = detail.stockOnHandQuantity
    this.baseUnitId = detail.baseUnitId
    this.updatePrice()
  }
  updatePrice(quantity = this.quantity, priceQuoteUnitPrice = this.priceQuoteUnitPrice) {
    this.priceQuoteTotalUnitPrice = quantity ? quantity * priceQuoteUnitPrice : null
  }
}
