import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import Orders from '../model/orders'
import ApprovedOrders from '../model/approvedRequestedOrders'
import moment from 'moment'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    orders: [],
    approveOrders: new Orders(),
    approvedRequestOrders: new ApprovedOrders(),
    Page: {
      number: 1,
      size: 20,
      total: 0,
      last: 1
    },
    OrderPage: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    orders: state => state.orders,
    isEmptyOrders: state => state.orders.length === 0,
    approveOrders: state => state.approveOrders.list,
    approvedRequestOrders: state => state.approvedRequestOrders,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    OrderPage: state => ({
      ...state.OrderPage,
      last: Math.ceil(state.OrderPage.total / state.OrderPage.size)
    }),
    isEmptyApproveOrderList: state => state.approveOrders.list.length === 0
  },
  actions: {
    async getRequestOrders({ commit, state }, payload = {storeList: []}) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.OrderPage.number })
      const results = await requestHandler({
        title: 'getRequestOrders',
        methodName: 'getRequestOrders',
        method: () => {
          return HTTP.post('Order/requested/all', {
            ...payload,
            PageSize: payload.pageSize || state.OrderPage.size,
            pageNumber: PageNumber
          })
        },
        success: result => {
          commit('SET_R_ORDERS_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getApprovedOrders({ commit, state }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'getApprovedOrders',
        methodName: 'getApprovedOrders',
        method: () => {
          return HTTP.post('Order/requested/header/all', {
            ...payload,
            PageSize: payload.pageSize || state.Page.size,
            pageNumber: PageNumber
          })
        },
        success: result => {
          commit('SET_APPROVED_ORDER_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getApprovedOrdersDetail({ commit }, headerId) {
      const results = await requestHandler({
        title: 'getApprovedOrdersDetail',
        methodName: 'getApprovedOrdersDetail',
        method: () => {
          return HTTP.post('Order/requested/detail', { headerId })
        },
        success: result => result
      })
      return results
    },
    async getPriceQuoteForRequest({ commit }, payload) {
      const results = await requestHandler({
        title: 'getPriceQuoteForRequest',
        methodName: 'getPriceQuoteForRequest',
        method: () => {
          return HTTP.post('PriceQuote/getbyrequestdetailid', payload)
        },
        success: result => result
      })
      return results
    },

    async sendRequestOrderToApprove({ commit }, payload) {
      const results = await requestHandler({
        title: 'sendDirectOrderToApprove',
        methodName: 'sendDirectOrderToApprove',
        method: () => {
          return HTTP.put('request/header/sendtoapprove', payload)
        },
        success: result => result
      })
      return results
    },

    async getPriceQuoteWithSupplier({ commit }, payload = {}) {
      const results = await requestHandler({
        title: 'getPriceQuoteWithSupplier',
        methodName: 'getPriceQuoteWithSupplier',
        method: () => {
          return HTTP.post('PriceQuote/getbysupplierid', payload)
        },
        success: result => result
      })
      return results
    },
    // async getOrderDetail({ commit }, orderHeaderId) {
    //   const results = await requestHandler({
    //     title: 'getOrderDetail',
    //     methodName: 'getOrderDetail',
    //     method: () => {
    //       return HTTP.post('Order/approved/detail', { orderHeaderId })
    //     },
    //     success: result => result
    //   })
    //   return results
    // },
    async makeRequestApprove({commit}, approvedRequestList) {
      const results = await requestHandler({
        title: 'makeRequestApprove',
        methodName: 'makeRequestApprove',
        method: () => {
          return HTTP.post('Order/approve', { approvedRequestList })
        },
        success: result => result
      })
      return results
    },

    async setOrderStatus({commit}, payload) {
      const results = await requestHandler({
        title: 'setOrderStatus',
        methodName: 'setOrderStatus',
        method: () => {
          return HTTP.put('Order/approved/header/setstatus', payload)
        },
        success: result => result
      })
      return results
    },

    async resetWMSStatus({commit}, payload) {
      const results = await requestHandler({
        title: 'resetWMSStatus',
        methodName: 'resetWMSStatus',
        method: () => {
          return HTTP.put('Order/approved/header/resetwmsstatus', payload)
        },
        success: result => result
      })
      return results
    },

    async orderResend({commit}, payload) {
      const results = await requestHandler({
        title: 'orderResend',
        methodName: 'orderResend',
        method: () => {
          return HTTP.put('Order/approved/header/resetstatus', payload)
        },
        success: result => result
      })
      return results
    },

    async getOrdersHeader({ state, commit }, payload) {
      const pageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      payload.pageNumber = pageNumber

      payload.withDetails = false
      const results = await requestHandler({
        title: 'getOrdersHeader',
        methodName: 'getOrdersHeader',
        method: () => {
          return HTTP.post('Order/approved/header/all', payload)
        },
        success: result => {
          commit('SET_ORDERS_LIST', result.data)
          return result
        }
      })
      return results
    },

    async updateApprovedOrder({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateApprovedOrder',
        methodName: 'updateApprovedOrder',
        method: () => {
          return HTTP.put('Order/approved/header', {
            id: payload.id,
            description: payload.description,
            orderDate: moment(payload.orderDate).format('YYYY-MM-DD')
          })
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async updateOrderedDespatchState({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'updateOrderedDespatchState',
        methodName: 'updateOrderedDespatchState',
        method: () => {
          return HTTP.put('Order/approved/header/state', item)
        },
        success: result => result
      })
      return results
    },

    async updateApprovedOrderDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateApprovedOrderDetail',
        methodName: 'updateApprovedOrderDetail',
        method: () => {
          return HTTP.put('Order/approved/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async createApprovedOrderDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'createApprovedOrderDetail',
        methodName: 'createApprovedOrderDetail',
        method: () => {
          return HTTP.post('Order/approved/detail/add', payload)
        },
        success: result => result
      })
      return results
    },
    async getOrderDetail({ commit }, header) {
      const results = await requestHandler({
        title: 'getOrderDetail',
        methodName: 'getOrderDetail',
        method: () => {
          return HTTP.post('Order/approved/detail', { orderHeaderId: header.id })
        },
        success: result => {
          commit('SET_ORDER_DETAIL', { header, detailList: result.data.approvedOrderDetailList })
          commit('SHOW_DETAIL', header)
          return result
        }
      })
      return results
    },
    async fetchApprovedOrderDetail({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchApprovedOrderDetail',
        methodName: 'fetchApprovedOrderDetail',
        method: () => {
          return HTTP.get('Order/approved/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async fetchApprovedOrderHeader({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchApprovedOrderHeader',
        methodName: 'fetchApprovedOrderHeader',
        method: () => {
          return HTTP.get('Order/approved/header', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async makeOrderRequestPassive({commit}, payload) {
      const results = await requestHandler({
        title: 'makeOrderRequestPassive',
        methodName: 'makeOrderRequestPassive',
        method: () => {
          return HTTP.put('Request/detail/set/passive', payload)
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_R_ORDERS_LIST(state, data) {
      state.orders = [
        ...data.requestedOrderList.map(item => ({
          ...item,
          requestDetailId: item.id,
          requestQuantity: item.quantity,
          supplierId: null,
          price: null,
          discount1: 0,
          discount2: 0,
          taxAmount: null,
          netAmount: null,
          grossAmount: null,
          isSupplierGlobal: false,
          priceType: null
        }))
      ]
      state.OrderPage.number = data.pageNumber
      state.OrderPage.total = data.totalCount
      state.OrderPage.size = data.pageSize
    },
    UPDATE_R_ORDER_LIST(state, { detail, payload }) {
      let order = state.orders.find(item => item.id === detail.id)
      let list = { ...detail, ...payload }
      if (order) {
        order.quantity = list.quantity
        order.price = list.price
        order.requestDate = list.requestDate
        order.discount1 = list.discount1
        order.discount2 = list.discount2
        order.netAmount = list.netAmount
        order.grossAmount = list.grossAmount
        order.taxAmount = list.taxAmount
        order.supplierId = list.supplierId
        order.taxId = list.taxId
        order.priceType = list.priceType
      }
    },
    SORT_BY_DATE_ORDER_LIST(state) {
      state.orders = state.orders.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate))
    },
    SET_ORDERS_LIST(state, data) {
      state.approveOrders.addItems(data.approvedOrderHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_ORDER_DETAIL(state, { header, detailList }) {
      header.addDetails(detailList)
    },
    // DETAIL
    //
    HIDE_DETAIL(state, orderHeader) {
      orderHeader.showDetail = false
    },
    SHOW_DETAIL(state, order) {
      order.showDetail = true
    },
    SET_APPROVED_ORDER_LIST(state, data) {
      state.approvedRequestOrders = data.requestedOrderHeaderList
    },

    HIDE_APPROVED_ORDER_DETAIL(state, transferApprovalHeader) {
      transferApprovalHeader.showDetail = false
    },

    SHOW_APPROVED_ORDER_DETAIL(state, { id, data }) {
      let requestedApprovedHeader = state.approvedRequestOrders.findItem(id)
      if (requestedApprovedHeader) {
        const detailList = data.requestedOrderList.map(item => {
          return {
            headerId: id,
            ...item
          }
        })
        requestedApprovedHeader.addDetails(detailList)
        requestedApprovedHeader.showDetail = true
      }
    }
  }
}
