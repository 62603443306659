var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "btn show-MiniPopup" },
    [
      _c("Icon", { attrs: { name: "icon-global-cardboard-box" } }),
      _c("div", { staticClass: "total-preview" }, [
        _c("div", { staticClass: "total-preview-row header" }, [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("DirectOrders.TotalPreviewHeaderTitle"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "total-preview-content" },
          _vm._l(_vm.list, function (item) {
            return _c("div", { staticClass: "total-preview-row" }, [
              _c("span", { staticClass: "total-preview-col unit" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _c("span", { staticClass: "total-preview-col quantity" }, [
                _vm._v(_vm._s((+item.quantity).toFixed(2))),
              ]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }