<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('Request.updateRequestDetail') }}
      div(v-else) {{ $t('Request.Request_form_detail') }}
    template(slot="detail")
      .info
        span {{ code }}
    template(slot="content")
      .selectType(v-if="!isEdit")
        SelectedButtons(
          v-model="type",
          :data="requestTypeOptions()"
        )

      form.Form(
      id="form-request-detail"
      @submit.prevent="onSubmitRequestDetail")
        .border-bottom-gray.m-bottom-25
          p.c-light.p-bottom-5.p-top-5(
          id="request-detail-popup-form-request-type"
          ) {{ $t('Request.table_Header_field_RType') }}
          p.c-success.font-size-bigger.m-bottom-5(
            id="request-detail-popup-form-request-type-text"
          ) {{ requestTypeText }}
        .Form-item.required(
          id="request-detail-popup-form-min-opt-item"
          v-if="type === 2"
        )
          label.Form-item-label
            span {{ $t('Request.detail-popup-min-opt-item-label') }}
          .control.flexbox.wrap
            CustomRadio(
              :id="`request-detail-popup-min-selection`"
              :label="$t('Request.detail-popup-min-select-label')"
              :checked="isMinStock"
              @change="changeMinOptType(true)")
            CustomRadio.m-top-10(
              :id="`request-detail-popup-opt-selection`"
              :label="$t('Request.detail-popup-opt-select-label')"
              :checked="!isMinStock"
              @change="changeMinOptType(false)")

        .Form-item.required(
          id="request-detail-popup-form-request-form"
        )
          label.Form-item-label
            span(v-if="isTransferType") {{ $t('Dictionary.toStore') }}
            span(v-else) {{ $t('Request.Form_store') }}
          .control.form-item-select
            custom-search(
              ref="firstInput"
              component-name="select-request-store-name"
              name="storeName",
              v-model="form.storeId"
              :data-vv-as="isTransferType ? $t('Dictionary.toStore') : $t('Request.Form_store')"
              v-validate="'required|is_not:-1'",
              :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
              :on-search="getOptionsStore",
              :options="selectMiniStoreList",
              :disabled="isEdit || isStoreDisabled"
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('Request.detail_Popup_FormField_StoreName_Unselected')"
              :loading="isSearchStoreLists"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
            )

        .Form-item.required(v-if="isTransferType")
          label.Form-item-label
            | {{ $t('Dictionary.fromStore') }}
          .control.form-item-select
            custom-search(
              ref="fromStoreInput"
              component-name="select-request-from-store-name"
              name="fromStoreName",
              v-model="form.fromStoreId"
              :data-vv-as="$t('Dictionary.fromStore')"
              v-validate="'required|is_not:-1'",
              :inputClass="{ 'is-danger': veeErrors.has('fromStoreName') }",
              :on-search="getOptionsFromStore",
              :options="selectMiniFromStoreList",
              :disabled="isEdit || isStoreDisabled"
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('Request.detail_Popup_FormField_StoreName_Unselected')"
              :loading="isSearchStoreLists"
              @input="inputFromSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('fromStoreName')"
              :errorName="veeErrors.first('fromStoreName')"
            )


        .Form-item.required
          label.Form-item-label {{ $t('Request.form_RequestDate') }}
          .control.form-item-select
            CustomDatepicker(
              id="request-form-rdate",
              name="RequestDate",
              :data-vv-as="$t('Request.form_RequestDate') ",
              v-model="form.requestDate",
              :class="{ 'is-danger': veeErrors.has(`RequestDate`) }"
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :position="'right'"
              :disabledStartDate="disableDate")
          showValidateError(
              v-show="veeErrors.has('RequestDate')"
              :errorName="veeErrors.first('RequestDate')"
            )

        template(v-if="type === 0 || isEdit")
          .Form-item.required
            label.Form-item-label {{ $t('Request.Form_StockItemName') }}
            .control
              custom-search(
                name="stockItem"
                component-name="form-request-detail-stock-item"
                v-model="form.stockItem"
                :data-vv-as="$t('Request.Form_StockItemName')"
                v-validate="'required'"
                :disabled="isEdit"
                ref="focusableInput"
                :inputClass="{ 'is-danger': veeErrors.has('stockItem') }"
                :on-search="getOptions"
                :options="selectOptions"
                label="name"
                :placeholder="$t('Request.Form_StockItemName_Placeholder')"
                :directSelectOption="directSelectOption"
                @input="stockUnitSelection"
              )

            showValidateError(
              v-show="veeErrors.has('stockItem')"
              :errorName="veeErrors.first('stockItem')"
            )

          .Form-item.required.row-field
            .item
              label.Form-item-label {{ $t('Request.Form_Quantity') }}
              .control
                customNumberInput(
                  id="request-form-quantity"
                  name="quantity"
                  :data-vv-as="$t('Dictionary.Quantity')"
                  v-model="form.quantity"
                  v-validate="{required: true, greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
                  :error="veeErrors.has('quantity')"
              )
              showValidateError(
                v-show="veeErrors.has('quantity')"
                :errorName="veeErrors.first('quantity')"
              )
            .item
              label.Form-item-label {{ $t('Request.Form_unitPrice') }}
              .control
                customNumberInput(
                  id="request-form-unit-price"
                  name="unitPrice"
                  :data-vv-as="$t('Dictionary.baseQuantity')"
                  v-model="form.unitPrice"
                  :disabled="true"
                  :error="veeErrors.has('unitPrice')"
              )
              showValidateError(
                v-show="veeErrors.has('unitPrice')"
                :errorName="veeErrors.first('unitPrice')"
              )

          .Form-item.required
            label.Form-item-label
              | {{ $t('Request.Form_BaseUnit') }}
            .control.form-item-select
              Icon(name="icon-down-arrow")
              
              customSelectInput(
                name="unitId"
                :optionData="filteredUnitList"
                v-model="form.unitId"
                v-validate="'required|is_not:-1'"
                :data-vv-as="$t('Request.Form_BaseUnit')"
                :error="veeErrors.has('unitId')"
                id="select-request-popup-baseunit"
                optionIdName="option-request-popup-baseunit"
                :disabled="isEdit"
              )

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )

          .Form-item
            label.Form-item-label {{ $t('Request.formFieldDescription') }}
            .control
            customTextareaInput(
              id="request-form-description"
              v-model="form.description"
              v-validate="'max:128'"
              name="description"
              :data-vv-as="$t('Request.formFieldDescription')"
              :error="veeErrors.has('description')"
              row="3"
              )

            showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

        template(v-else-if="type === 1")
          .Form-item(:class="isOnlyListUsage ? 'required' : ''")
            label.Form-item-label {{ $t('Request.Form_List') }}
            .control
              custom-search(
                name="list"
                component-name="request-form-list"
                v-model="form.list"
                :data-vv-as="$t('Request.Form_List') "
                ref="focusableInput"
                :class="{ 'is-danger': veeErrors.has('list') }"
                :on-search="getOptionsRequestList"
                :searchAll="true"
                :options="selectOptionsRList"
                v-validate="{required: isOnlyListUsageCalculate}"
                label="name"
                :placeholder="$t('Request.Form_List_Placeholder')"
              )
            showValidateError(
              v-show="veeErrors.has('list')"
              :errorName="veeErrors.first('list')"
            )
          .selected-items
            Button(
              v-for="(item,index) in lists",
              :key="index",
              seashell,
              :id="`selected-stock-item-${index}`"
              @click="removeListItem(item)"
            )
              span {{ item.name }}
              Icon.m-left-5(
                name="icon-popup-close"
                :id="`selected-stock-item-delete-${index}`"
              )
        template(v-else)
          .Form-item
            label.Form-item-label {{ $t('Request.AllGroupList') }}
            .control
              CustomMultiselect.toolbar-filters__item.m-top-10(
                id="request-store-filter"
                :items="groupList"
                :selected="groupFilter"
                :unselectedText="$t('Request.toolbar_Stores_Unselected')"
                :popupHeader="$t('Request.AllGroupList')"
                :allLabel="$t('Request.toolbar_Stores_AllLabel')"
                :buttonText="$t('Request.toolbar_Stores_ButtonText')"
                buttonIconLeft="btn-success"
                @delegateCheckAll="onChangeGroupFilter"
                @delegateCheckItem="onChangeGroupFilter")
              
    template(slot="footer")
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-request-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Request.Request_form_detail_name') })"
        v-model="saveAndNew")

      Button.item(
      id="btn-request-popup-submit"
      size="medium"
      primary
      variant="full"
      type="submit",
      form="form-request-detail",
      :disabled="isLoading || (type === 1 && lists.length === 0) || veeErrors.has('description')")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Request.updateRequestDetail') }}
          template(v-else) {{ $t('Request.addRequestDetail') }}
        Loading(theme="disable", v-show="isLoading")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
//
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RequestDetailForm',
  data () {
    return {
      type: 0,
      form: {
        list: null,
        storeId: {
          id: '-1',
          name: ''
        },
        fromStoreId: {
          id: '-1',
          name: ''
        },
        requestDate: moment().hour(24).minute(0)._d,
        stockItem: null,
        quantity: null,
        unitId: '',
        unitPrice: null,
      },
      detailObject: {},
      disableDate: {
        to: new Date()
      },
      saveAndNew: true,
      selectOptions: [],
      selectOptionsRList: [],
      selectMiniStoreList: [],
      selectMiniFromStoreList: [],
      lists: [],
      headerStoreId: null,
      isStoreDisabled: false,
      isMinStock: true,
      isOnlyListUsage: false,
      groupList: [],
      groupFilter: [],
    }
  },
  computed: {
    ...mapGetters('ListsInventory', [
      'InventoryHeaderList'
    ]),

    ...mapGetters('Stores', [
      'miniStoreList',
      'storeList'
    ]),
    ...mapGetters('Settings', [
      'UserInfo',
      'permissions',
      'TransferRequestStorePermissionList',
      'checkPricePermission'
    ]),

    ...mapGetters('Units', [
      'BaseUnitList',
      'findUnit',
      'getRelatedUnitList'
    ]),

    ...mapGetters('Group', ['allItemGroups']),

    isLoading () {
      return this.$wait.is(['createRequestHeaderDetail', 'updateRequestHeaderDetail'])
    },

    isEdit () {
      return !!this.$route.params.detailId
    },

    isOnlyListUsageCalculate () {
      return this.isOnlyListUsage && this.type === 1 && this.form.list?.length === 0
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    code () {
      return this.$route.params.code
    },

    requestType () {
      return parseInt(this.$route.params.type)
    },

    requestTypeText () {
      switch (this.requestType) {
        case 2:
          return this.$t('Request.type_order')
        case 3:
          return this.$t('Request.type_transfer')
        default:
          return ''
      }
    },

    isTransferType () {
      return this.requestType === 3
    },

    filteredUnitList () {
      if (!this.form.stockItem) return []
      if (this.isEdit) {
        return [{
          id: this.detailObject.unitId,
          name: this.detailObject.unitName
        }]
      }
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(this.form.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    directSelectOption () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    }
  },
  methods: {
    ...mapActions('ListsRequestTransfer', ['searchRequestTransferList']),
    ...mapActions('ListsRequestOrder', ['searchRequestOrderList']),
    ...mapActions('Stores', [
      'getStoreList',
      'fetchStoreByName',
      'getStore'
    ]),
    ...mapActions('Stock', ['searchStockItem']),

    ...mapActions('Group',['getItemGroupAll']),
    ...mapActions('Request', [
      'createRequestHeaderDetail',
      'updateRequestHeaderDetail',
      'getRequestDetail',
      'showRequestDetailAction',
      'getRequestDetailByItem',
      'getRequestHeaderByItem'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    addList (value) {
      if (!value || this.lists.some(item => item.id === value.id)) return
      this.lists.push(value)
      this.$nextTick(() => {
        this.form.list = null
      })
    },

    /* formFocus () {
      this.form.fromStoreId.id === '-1' && this.isTransferType
        ? this.$refs.fromStoreInput.focus() : this.$refs.focusableInput.$refs.search.focus()
    },
    */

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    requestTypeOptions () {
      if (this.isOnlyListUsage) this.type = 1
      return [
        { text: this.$t('Request.form_type_stock_item'), value: 0 },
        { text: this.$t('Request.form_type_list'), value: 1 },
        { text: this.$t('Request.form_type_min_opt_stock'), value: 2 }
      ]
    },

    formReset () {
      this.form = {
        storeId: this.form.storeId,
        fromStoreId: this.form.fromStoreId,
        requestDate: this.form.requestDate,
        stockItem: null,
        list: null,
        quantity: null,
        unitId: '',
        unitPrice: null
      }
      this.selectOptions = []
      this.selectOptionsRList = []
      this.selectMiniStoreList = []
      this.lists = []
      this.isStoreDisabled = true
      this.isMinStock = true
      this.setGroupFilterList()
    },

    async getStoreId (headerId) {
      await this.getRequestHeaderByItem(headerId)
        .then(res => {
          this.isStoreDisabled = !!res.data.requestHeader.requestDetailStoreId
          this.headerStoreId = res.data.requestHeader.requestDetailStoreId || this.UserInfo.defaultStoreId
          if (res.data.requestHeader.requestDetailFromStoreId) this.form.fromStoreId = res.data.requestHeader.requestDetailFromStoreId
        })
    },

    getOptions (search) {
      const types = this.isTransferType ? [1, 2, 3] : [1, 2, 4]
      this.searchStockItem({ Text: search, types })
        .then(res => {
          this.selectOptions = res.data.items
        })
    },
    getOptionsRequestList (search) {
      const searcAction = this.isTransferType ? this.searchRequestTransferList : this.searchRequestOrderList
      searcAction(search)
        .then(res => {
          this.selectOptionsRList = this.isTransferType ? res.data.requestTransferListHeaderList : res.data.requestOrderListHeaderList
        })
    },
    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },
    getOptionsFromStore (search) {
      this.fetchStoreByName({name: search, useTransferStorePermission: true})
        .then(res => {
          if (res) {
            this.selectMiniFromStoreList = res.data.items
            const index = this.selectMiniFromStoreList.findIndex(item => item.id === this.form.storeId.id)
            if (index !== -1) this.selectMiniFromStoreList.splice(index, 1)
          }
        })
    },
    inputSelection (data) {
      if(data.length !== 0) {
        this.form.storeId.id = data.id
        this.form.storeId.name = data.name
      }
    },
    inputFromSelection (data) {
      if(data.length !== 0) {
        this.form.fromStoreId.id = data.id
        this.form.fromStoreId.name = data.name
      }
    },
    resetOptions () {
      this.selectMiniStoreList = []
      this.selectMiniFromStoreList = []
      this.getOptionsStore('')
      this.getOptionsFromStore('')
    },

    async addRequest () {
      const requestData = {
        requestHeaderId: this.$route.params.id,
        storeId: this.form.storeId.id,
        requestDate: moment(this.form.requestDate).format('YYYY-MM-DD'),
        unitPrice: this.form.unitPrice
      }
      if (this.type === 0) {
        requestData.stockItemId = this.form.stockItem.id
        requestData.unitId = this.form.unitId
        requestData.quantity = this.form.quantity
        requestData.description = this.form.description
      } else if (this.type === 2) {
        requestData.itemGroupIds = this.groupFilter.map(({ id }) => (id))
        requestData.storeItemType = (this.isMinStock) ? 1 : 2
      } else {
        const listIds = [...this.lists.map(_ => _.id)]
        requestData.requestTransferListHeaderIds = this.isTransferType ? listIds : []
        requestData.requestOrderListHeaderIds = !this.isTransferType ? listIds : []
      }
      if (this.isTransferType) {
        requestData.fromStoreId = this.form.fromStoreId.id
      }
      const message = this.$t('Request.request_add_message')
      const obj = {
        type: this.type,
        payload: requestData
      }
      const res = await this.createRequestHeaderDetail(obj)
      if (!res) return false
      if (this.saveAndNew) {
        const detailRequest = await this.getRequestDetail(this.$route.params.id)
        if (detailRequest) {
          await this.showRequestDetailAction({ requestHeaderId: this.$route.params.id, data: detailRequest.data, permission: this.checkPricePermission('RequestMenu') })
        }
        this.getRequestHeaderByItem(this.$route.params.id)
        this.formReset()
        this.$nextTick(() => {
          //this.formFocus()
          this.$validator.reset()
        })
      } else {
        const detailRequest = await this.getRequestDetail(this.$route.params.id)
        if (detailRequest) {
          this.showRequestDetailAction({ requestHeaderId: this.$route.params.id, data: detailRequest.data , permission: this.checkPricePermission('RequestMenu')})
        }
        this.getRequestHeaderByItem(this.$route.params.id)
        this.close()
      }
      this.notifyShow({ message })
    },

    async updateRequest () {
      const payload = getObjectValuesByKey(this.form, ['quantity', 'description'])
      payload.requestDate = moment(this.form.requestDate).format('YYYY-MM-DD')
      payload.unitPrice = this.form.unitPrice
      payload.id = this.$route.params.detailId
      const message = this.$t('Request.request_update_message')
      const res = await this.updateRequestHeaderDetail(payload)
      if (!res) return false
      const detailRequest = await this.getRequestDetail(this.$route.params.id)
      if (detailRequest) {
        this.showRequestDetailAction({ requestHeaderId: this.$route.params.id, data: detailRequest.data, permission: this.checkPricePermission('RequestMenu') })
      }
      this.getRequestHeaderByItem(this.$route.params.id)
      this.close()
      this.notifyShow({ message })
    },

    onSubmitRequestDetail: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async valid => {
        if (!valid) return
        if (this.isEdit) await this.updateRequest()
        else await this.addRequest()
      })
    }, 'submitForm'),

    setUnit (data) {
      this.form.unitId = data.unitId
      this.form.unitPrice = this.checkPricePermission('RequestMenu') ? data.unitPrice : null
    },

    removeListItem (item) {
      this.lists.splice(this.lists.indexOf(item), 1)
    },
    setForm (item) {
      this.detailObject = item
      this.detailObject.storeId = this.headerStoreId
      this.form = {
        storeId: { id: this.detailObject.storeId, name: this.detailObject.storeName },
        fromStoreId: { id: this.detailObject.fromStoreId, name: this.detailObject.fromStoreName },
        requestDate: moment(this.detailObject.requestDate).format('YYYY-MM-DD'),
        quantity: this.detailObject.quantity,
        unitId: this.detailObject.unitId,
        stockItem: {
          id: this.detailObject.stockItemId,
          name: this.detailObject.stockItemName,
        },
        unitPrice: this.detailObject.unitPrice,
        description: this.detailObject.description
      }
    },
    changeMinOptType (type) {
      this.isMinStock = type
    },

    onChangeGroupFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.groupFilter = [...this.groupList]
        } else {
          this.groupFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.groupFilter = []
        } else {
          this.groupFilter.splice(this.groupFilter.indexOf(event.item), 1)
        }
      }
    },

    setGroupFilterList () {
      this.groupList = this.allItemGroups.map(item => {
        if (item.name) {
          return {
            id: item.id,
            name: item.name
          }
        }
      })
      this.groupFilter = [...this.groupList]
    },

    async stockUnitSelection (option) {
      if (!option || this.isEdit) return
      await this.setUnit(option)
    }

  },
  async mounted () {
    if (this.allItemGroups.length === 0) await this.getItemGroupAll()
    await this.getStoreList({pageNumber: 1, pageSize: 9999, ignorePermission: true})
    await this.getStoreId(this.$route.params.id)
    //this.formFocus()
    if (this.isEdit) {
      if (this.$route.params.item) {
        this.setForm(this.$route.params.item)
      } else {
        this.getRequestDetailByItem(this.$route.params.detailId)
          .then(res => {
            this.setForm(res.data.requestDetail)
          })
      }
    } else {
      this.setGroupFilterList()
      const detail = await this.getRequestHeaderByItem(this.$route.params.id)
      if (this.isTransferType) {
        if (detail.data.requestHeader.requestDetailStoreId) {
          const fromStore = await this.getStore(detail.data.requestHeader.requestDetailFromStoreId)
          this.form.fromStoreId = { id: fromStore.data.store.id, name: fromStore.data.store.name }
        }
      }
      if (detail.data.requestHeader.requestDetailStoreId) {
        const store = await this.getStore(detail.data.requestHeader.requestDetailStoreId)
        this.form.storeId = { id: detail.data.requestHeader.requestDetailStoreId, name: store.data.store.name }
      } else this.form.storeId = { id: this.UserInfo.defaultStoreId, name: this.UserInfo.defaultStoreName }
      this.permissions.find(i => {
        if (i.permission === 221) this.isOnlyListUsage = true
      })
    }
    this.selectMiniFromStoreList = this.TransferRequestStorePermissionList
  },
  watch: {
    async 'form.list' (value) {
      this.addList(value)
    },
    lists () {
      if (this.lists.length === 0) {
        this.form.list = null
        this.selectOptionsRList = []
      }
    },
    async type (value) {
      //await this.formFocus()
      await this.$validator.reset()
      if (value === 0) {
        this.form.list = null
        this.selectOptionsRList = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

:deep() .dropdown-handle-button {
  height: 54px;
  padding-top: 7px;
}
.info {
  margin-top: 10px;
  font-weight: bold;
}
.selected-items {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .btn {
    width: auto;
    height: 34px;
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
