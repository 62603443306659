export function getRequestOrderFields($t) {
  return [
    {
      name: 'storeName',
      is_visible: true,
      fixedColumnHeader: true,
      get short_title() {
        return $t('Orders.table_fieldStore')
      },
      get title() {
        return $t('Orders.table_fieldStore')
      }
    },
    {
      name: 'itemGroupName',
      fixedColumnHeader: true,
      is_visible: true,
      get short_title() {
        return $t('Orders.table_itemGroupName')
      },
      get title() {
        return $t('Orders.table_itemGroupName')
      }
    },
    {
      name: 'stockItemName',
      fixedColumnHeader: true,
      is_visible: true,
      get short_title() {
        return $t('Orders.table_fieldStock')
      },
      get title() {
        return $t('Orders.table_fieldStock')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('Orders.table_fieldUnit')
      },
      get title() {
        return $t('Orders.table_fieldUnit')
      }
    },
    {
      name: 'requestQuantity',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Orders.table_fieldRequestQuantity')
      },
      get title() {
        return $t('Orders.table_fieldRequestQuantity')
      }
    },
    {
      name: 'quantity',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Orders.table_fieldQuantity')
      },
      get title() {
        return $t('Orders.table_fieldQuantity')
      }
    },
    {
      name: 'requestDate',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Orders.table_fieldDate')
      },
      get title() {
        return $t('Orders.table_fieldDate')
      }
    },
    {
      name: 'supplier',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Orders.table_fieldSupplier')
      },
      get title() {
        return $t('Orders.table_fieldSupplier')
      }
    },
    {
      name: 'price',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Orders.table_fieldPrice')
      },
      get title() {
        return $t('Orders.table_fieldPrice')
      }
    },
    {
      name: 'discount1',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Orders.table_fieldDiscount1')
      },
      get title() {
        return $t('Orders.table_fieldDiscount1')
      }
    },
    {
      name: 'discount2',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Orders.table_fieldDiscount2')
      },
      get title() {
        return $t('Orders.table_fieldDiscount2')
      }
    },
    {
      name: 'tax',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Orders.table_fieldTax')
      },
      get title() {
        return $t('Orders.table_fieldTax')
      }
    },
    {
      name: 'taxAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Orders.table_fieldKDV')
      },
      get title() {
        return $t('Orders.table_fieldKDV')
      }
    },
    {
      name: 'netAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Orders.table_fieldTotalAmount')
      },
      get title() {
        return $t('Orders.table_fieldTotalAmount')
      }
    },
    {
      name: 'grossAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Orders.table_fieldBRAmount')
      },
      get title() {
        return $t('Orders.table_fieldBRAmount')
      }
    },
    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}
