<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Request.removeFormTitle')",
  loading="deleteRequestHeader",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RequestHeaderRemove',

  data () {
    return {
      fields: [{
        name: 'code',
        is_visible: true,
        short_title: this.$t('Request.table_Header_field_RNumber'),
        title: this.$t('Request.table_Header_field_RNumber')
      }],
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.getRequestHeaderByItem(this.$route.params.id)
        .then(res => {
          this.item = res.data.requestHeader
        })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Request', [
      'DeleteRequestHeader',
      'getRequestHeaderByItem'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      let result = await this.DeleteRequestHeader(this.item.id)
      if (result) {
        const message = this.$t('Request.removeRequest_Notificiation_Deleted')
        this.notifyShow({ message })
        await this.$emit('getRequestList')
        this.close()
      }
    }, 'submitForm')
  }
}
</script>

<style lang="scss">
</style>
