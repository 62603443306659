import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import Requests from '../model'
import { getRequestDetailFields } from '../fields/requests'
import { setTranslations } from '@/i18n'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    requestHeaderLinks: [
      'AllOrders',
      'Requests',
      'RequestsOrder',
      'RequestsTransfer',
      'Orders'
    ],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    requestDetailFields: [],
    requests: new Requests()
  },
  getters: {
    requestHeaderLinks: state => state.requestHeaderLinks,
    requestList: state => state.requests.list,
    isRequestListEmpy: state => state.requests.list.length === 0,
    requestDetailFields: state => state.requestDetailFields,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {

    async getRequestHeaderAll({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'getRequestHeaderAll',
        methodName: 'getRequestHeaderAll',
        method: () => {
          return HTTP.post('Request/header/all', {
            ...payload,
            PageSize: payload.pageSize,
            pageNumber: PageNumber
          })
        },
        success: result => {
          commit('SET_ALL_REQUEST', result.data)
          return result
        }
      })
      return results
    },

    async createRequestDetailWithoutHeader({ commit }, payload) {
      const results = await requestHandler({
        title: 'createRequestDetailWithoutHeader',
        methodName: 'createRequestDetailWithoutHeader',
        method: () => {
          return HTTP.post('Request/detail/withoutheader', payload)
        },
        success: result => result
      })
      return results
    },

    async createRequestHeader({ commit }, payload) {
      const results = await requestHandler({
        title: 'createRequestHeader',
        methodName: 'createRequestHeader',
        method: () => {
          return HTTP.post('Request/header', payload)
        },
        success: result => result
      })
      return results
    },

    async updateRequestHeader({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateRequestHeader',
        methodName: 'updateRequestHeader',
        method: () => {
          return HTTP.put('Request/header', payload)
        },
        success: result => result
      })
      return results
    },

    async resetWMSStatus({ commit }, payload) {
      const results = await requestHandler({
        title: 'resetWMSStatus',
        methodName: 'resetWMSStatus',
        method: () => {
          return HTTP.put('Request/header/resetwmsstatus', payload)
        },
        success: result => result
      })
      return results
    },

    async DeleteRequestHeader({ commit }, Id) {
      const results = await requestHandler({
        title: 'deleteRequestHeader',
        methodName: 'deleteRequestHeader',
        method: () => {
          return HTTP.delete('Request/header', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async getRequestDetail({ commit }, requestHeaderId) {
      const results = await requestHandler({
        title: 'getRequestDetail',
        methodName: 'getRequestDetail',
        method: () => {
          return HTTP.post('Request/detail/getbyheaderid', { requestHeaderId })
        },
        success: result => result
      })
      return results
    },

    async getRequestHeaderByItem({ commit }, Id) {
      const results = await requestHandler({
        title: 'getRequestHeaderByItem',
        methodName: 'getRequestHeaderByItem',
        method: () => {
          return HTTP.get('Request/header', {params: { Id }})
        },
        success: result => {
          commit('UPDATE_REQUEST_HEADER', result.data)
          return result
        }
      })
      return results
    },

    async getRequestDetailByItem({ commit }, Id) {
      const results = await requestHandler({
        title: 'getRequestDetailByItem',
        methodName: 'getRequestDetailByItem',
        method: () => {
          return HTTP.get('Request/detail', {params: { Id }})
        },
        success: result => result
      })
      return results
    },

    async createRequestHeaderDetail({ commit }, obj) {
      const url = obj.type === 0 ? 'Request/detail' : obj.type === 1 ? 'Request/detail/list' : 'Request/detail/storeitem'
      const results = await requestHandler({
        title: 'createRequestHeaderDetail',
        methodName: 'createRequestHeaderDetail',
        method: () => {
          return HTTP.post(url, obj.payload)
        },
        success: result => result
      })
      return results
    },
    async updateRequestHeaderDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateRequestHeaderDetail',
        methodName: 'updateRequestHeaderDetail',
        method: () => {
          return HTTP.put('Request/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async DeleteRequestDetail({ commit }, Id) {
      const results = await requestHandler({
        title: 'DeleteRequestDetail',
        methodName: 'DeleteRequestDetail',
        method: () => {
          return HTTP.delete('Request/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async makeRequestSaved({ commit }, Id) {
      const results = await requestHandler({
        title: 'makeRequestSaved',
        methodName: 'makeRequestSaved',
        method: () => {
          return HTTP.put('Request/save', { Id })
        },
        success: result => result
      })
      return results
    },

    async makeRequestFinalizeTransfer({ commit }, Id) {
      const results = await requestHandler({
        title: 'makeRequestFinalize',
        methodName: 'makeRequestFinalize',
        method: () => {
          return HTTP.put('Request/finalize/transfer', { Id })
        },
        success: result => result
      })
      return results
    },
    async makeRequestFinalizeOrder({ commit }, Id) {
      const results = await requestHandler({
        title: 'makeRequestFinalizeOrder',
        methodName: 'makeRequestFinalizeOrder',
        method: () => {
          return HTTP.put('Request/finalize/order', { Id })
        },
        success: result => result
      })
      return results
    },

    async showRequestDetailAction({ commit }, { requestHeaderId, data , permission}) {
      const i18n = await setTranslations()
      commit('SHOW_REQUEST_DETAIL', { i18n, requestHeaderId, data , permission })
    }
  },

  mutations: {
    SET_ALL_REQUEST(state, data) {
      state.requests.addItems(data.requestHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    UPDATE_REQUESTS_ITEM(state, item) {
      state.requests.updateItem(item)
    },

    UPDATE_REQUEST_HEADER(state, data) {
      // BE datayı tam döndüğünde bu mutation yerini Update Request Header Item'e bırakacak modelden update olacak, geçici olarak bu şekilde yapıldı.
      const itemIndex = state.requests.list.findIndex(item => item.id === data.requestHeader.id)
      if (itemIndex > -1) {
        state.requests.list[itemIndex].totalAmount = data.requestHeader.totalAmount
      }
    },
     //
    // DETAIL
    //
    HIDE_DETAIL(state, requestHeader) {
      requestHeader.showDetail = false
    },

    HIDE_OTHERS_DETAILS(state, requestHeader) {
      const others = state.requests.list.filter(request => request.id !== requestHeader.id)
      others.forEach(otherrequest => {
        otherrequest.showDetail = false
      })
    },

    RESET_REQUEST(state) {
      state.Page = {
        number: 1,
        size: 20,
        total: 0
      }
      state.requestDetailFields = []
      state.requests = new Requests()
    },

    SHOW_REQUEST_DETAIL(state, { i18n, requestHeaderId, data , permission}) {
      this.commit('Request/HIDE_OTHERS_DETAILS', {
        id: requestHeaderId
      })

      let request = state.requests.findItem(requestHeaderId)
      state.requestDetailFields = getRequestDetailFields(i18n, request, permission)
      request.addDetails(
        data.requestDetailList.map(item => {
          return {
            ...item,
            headerId: requestHeaderId,
            type: request.requestType
          }
        })
      )
      request.showDetail = true
    },

    SHOW_DETAIL(state, { requestHeaderId, data }) {
      let request = state.requests.findItem(requestHeaderId)
      request.addDetails(
        data.requestDetailList.map(item => {
          return {
            ...item,
            headerId: requestHeaderId
          }
        })
      )
      request.showDetail = true
    }
  }
}
