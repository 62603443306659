<template lang="pug">
Popup

    template(slot="title")
      div(v-if="isEdit") {{ $t('Global.editDataDetail', { field: $t('Orders.moduleNameWithPrefix') }) }}
      div(v-else) {{ $t('Global.addDataDetail', { field: $t('Orders.moduleName') }) }}

    template(slot="content")
      Loading(v-if="isSettingUpdateData")
      form.Form(
        v-else,
        id="approved-order-detail-form",
        @submit.prevent="onSubmitForm"
      )
        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.StockItem') }}
          .control
            custom-search(
              name="stockItem"
              component-name="form-approved-order-detail-stock-item"
              v-model="form.stockItem"
              :data-vv-as="$t('Dictionary.StockItem')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') }"
              ref="firstField"
              :on-search="getOptions"
              :options="selectOptions"
              :disabled="isEdit"
              label="name"
              :placeholder="$t('Global.search_Placeholder')"
              :directSelectOption="directSelectOption"
            )

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('stockItem')"
          ) {{ veeErrors.first('stockItem') }}

        .Form-item-info(v-if="form.stockItem")
          div
            .text {{ $t('Despatches.detail_Popup_FormField_StockItemName_InfoBaseUnit') }}
            .value {{ form.stockItem.baseUnitName }}
          div
            .text {{ $t('Despatches.detail_Popup_FormField_StockItemName_InfoTax') }}
            .value
              select.txt.tax(
              name="taxId"
              :class="{ 'is-danger': veeErrors.has('taxId') }"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Tax')"
              v-model="form.taxId")
                option(value="-1" id="option-despatch-popup-unselected") {{ $t('Despatches.detail_Popup_FormField_TaxId_Unselected') }}
                option(
                v-for="Tax in Taxes"
                :key="Tax.id"
                :value="Tax.id"
                :id="`option-despatch-popup-${Tax.id}`"
                ) {{ '%' + Tax.ratio + ' - '+ Tax.name }}

        .Form-item
          p.Form-item-help.is-danger(
            v-show="veeErrors.has('taxId')"
          ) {{ veeErrors.first('taxId') }}

        .Form-item.required
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Quantity') }}

          .control
            input.txt.no-spinners(
            name="quantity"
            id="approved-order-form-quantity"
            autocomplete="off"
            :data-vv-as="$t('Despatches.detail_Popup_FormField_Quantity')"
            v-model.number="form.quantity"
            v-validate="{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, multiplyTwoField: form.price, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
            :class="{ 'is-danger': veeErrors.has('quantity') }"
            type="number"
            step="any")

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('quantity')"
          ) {{ veeErrors.first('quantity') }}

        .Form-item.required
          label.Form-item-label
            | {{ $t('Despatches.detail_Popup_FormField_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
            id="select-despatch-popup-baseunit"
            name="unitId"
            v-model="form.unitId"
            v-validate="'required|is_not:-1'"
            :data-vv-as="$t('Despatches.detail_Popup_FormField_BaseUnit')"
            :disabled="isEdit"
            :class="{ 'is-danger': veeErrors.has('unitId') }")
              option(
              v-if="!isEdit"
              value="-1"
              id="option-orders-detail-popup-baseunit-unselected") {{ $t('Despatches.detail_Popup_FormField_BaseUnit_Unselected') }}
              option(
              v-for="unit in filteredUnitList"
              :key="unit.id"
              :value="unit.id"
              :id="`option-orders-detail-popup-baseunit-${unit.name}`"
              ) {{ unit.name }}

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('unitId')"
          ) {{ veeErrors.first('unitId') }}

        template(v-if="!headerInfo.supplierIsZeroPriceAvailable")
          .Form-item.required
            label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_NetUnitPrice') }}
            .control
              currency-input.txt(
              name="unitPrice"
              :class="{ 'is-danger': veeErrors.has('unitPrice') }"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_NetUnitPrice')"
              v-model="form.price"
              id="despatches-detail-unit-price"
              v-validate="{required: true, greater_than:0, multiplyTwoField: form.quantity}")

            p.Form-item-help.is-danger(
            v-show="veeErrors.has('unitPrice')"
            ) {{ veeErrors.first('unitPrice') }}

          .Form-item.flexbox
            .item.m-right-10
              label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Discount1') }}
              .control

                customNumberInput(
                  id="despatches-detail-discount1"
                  name="discount1"
                  :data-vv-as="$t('Despatches.detail_Popup_FormField_Discount1')"
                  :placeholder="$t('Despatches.popupForm_Field_Discount1_Placeholder')"
                  :error="veeErrors.has('discount1')"
                  v-validate="'greater_than:-1|max_value:100'"
                  v-model="form.discount1"
                )

                showValidateError(
                  v-show="veeErrors.has('discount1')"
                  :errorName="veeErrors.first('discount1')"
                )

            .item
              label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Discount2') }}
              .control
                customNumberInput(
                  :disabled="(+(form.discount1) <= 0)"
                  id="despatches-detail-discount2"
                  name="discount2"
                  :data-vv-as="$t('Despatches.detail_Popup_FormField_Discount2')"
                  :placeholder="$t('Despatches.popupForm_Field_Discount2_Placeholder')"
                  :error="veeErrors.has('discount2')"
                  v-validate="'greater_than:-1|max_value:100'"
                  v-model="form.discount2"
                )

                showValidateError(
                  v-show="veeErrors.has('discount2')"
                  :errorName="veeErrors.first('discount2')"
                )

        .Form-item
          label.Form-item-label {{ $t('Waste.form_description') }}
          .control
            textarea.txt(
            name="description"
            :data-vv-as="$t('Waste.form_description')"
            v-model="form.description"
            :class="{ 'is-danger': veeErrors.has('description')}",
            rows="5"
            v-validate="'max:512'",
            type="text")

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('description')"
            ) {{ veeErrors.first('description') }}

    template(slot="footer")
      Button.uppercase(
      id="approved-order-detail-form-action-button"
      type="submit",
      variant="full"
      form="approved-order-detail-form",
      primary,
      size="medium"
      :isLoading="isRunAction",
      :disabled="isRunAction")
        span(v-if="isEdit") {{ $t('Global.editDataDetail', { field: $t('Orders.moduleNameWithPrefix') }) }}
        span(v-else) {{ $t('Global.addDataDetail', { field: $t('Orders.moduleName') }) }}
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ApprovedOrderDetailForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      selectOptions: [],
      detailInfo: {},
      headerInfo: {},
      priceQuoteInfo: {},
      form: {
        stockItem: null,
        taxId: '-1',
        quantity: null,
        price: 0,
        unitId: '-1',
        discount1: '',
        discount2: '',
        description: ''
      }
    }
  },
  computed: {

    ...mapGetters('Stores', [
      'miniStoreList'
    ]),

    ...mapGetters('Tax', [
      'Taxes'
    ]),

    isEdit () {
      return this.status === 'edit'
    },

    isSettingUpdateData () {
      return this.$wait.is('setUpdatingData')
    },

    ...mapGetters('Units', [
      'findUnit',
      'UnitList',
      'getRelatedUnitList'
    ]),

    filteredUnitList () {
      if (!this.form.stockItem) return []
      if (this.isEdit) {
        return [{
          id: this.form.unitId,
          name: this.detailInfo.unitName
        }]
      }
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(this.form.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    isRunAction () {
      return this.$wait.is(['createApprovedOrderDetail', 'updateApprovedOrderDetail'])
    },

    directSelectOption () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    }

  },

  methods: {

    ...mapActions('Stock', ['searchStockItem']),

    ...mapActions('Tax', ['getTaxes']),

    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('Orders', ['HIDE_DETAIL', 'SHOW_DETAIL']),

    ...mapActions('Orders', [
      'createApprovedOrderDetail',
      'updateApprovedOrderDetail',
      'fetchApprovedOrderDetail',
      'getPriceQuoteWithSupplier',
      'fetchApprovedOrderHeader'
    ]),

    async getPriceQuoteInfo () {
      const res = await this.getPriceQuoteWithSupplier({
        supplierId: this.headerInfo.supplierId,
        date: moment(this.headerInfo.orderDate).format('YYYY-MM-DD'),
        stockItemId: this.form.stockItem.id,
        unitId: this.form.unitId
      })
      if (res && res.data.priceQuote) {
        const { priceQuote } = res.data
        this.priceQuoteInfo = priceQuote
        this.form.taxId = priceQuote.taxId
        this.form.discount1 = priceQuote.discount1
        this.form.discount2 = priceQuote.discount2
        this.form.price = priceQuote.unitPrice
      }
    },

    getOptions (search) {
      this.searchStockItem({Text: search, types: [1, 2, 4]})
        .then(res => {
          this.selectOptions = res.data.items
        })
    },

    async setUpdatingData () {
      const res = await this.fetchApprovedOrderDetail(this.$route.params.detailId)
      if (res) {
        this.detailInfo = await res.data.orderDetail
        //
        this.selectOptions = [
          { id: this.detailInfo.stockItemId,
            name: this.detailInfo.stockItemName
          }
        ]
        //
        this.form = {
          stockItem: {
            id: this.detailInfo.stockItemId,
            name: this.detailInfo.stockItemName,
            baseUnitName: this.detailInfo.baseUnitName
          },
          taxId: this.detailInfo.taxId,
          quantity: this.detailInfo.quantity,
          price: this.detailInfo.price,
          unitId: this.detailInfo.unitId,
          discount1: this.detailInfo.discount1,
          discount2: this.detailInfo.discount2,
          description: this.detailInfo.description
        }
      }
    },

    async getHeaderData () {
      const headerData = await this.fetchApprovedOrderHeader(this.$route.params.id)
      if (headerData) {
        this.headerInfo = headerData.data.orderHeader
      }
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    async addNewStock () {
      const res = await this.createApprovedOrderDetail({
        orderHeaderId: this.$route.params.id,
        ...this.form,
        stockItemId: this.form.stockItem.id
      })
      if (res) {
        // const headerData = await this.$emit('getOrdersHeaderAll')
        // if (headerData) {
        //   this.SHOW_DETAIL(this.$route.params.id)
        // }
        await this.$emit('showOrderWithDetail', this.headerInfo)
        this.closePopup()
        const message = this.$t('Global.notification_Created', { name: this.form.stockItem.name })
        this.notifyShow({ message })
      }
    },

    async editDetailItem () {
      const res = await this.updateApprovedOrderDetail({
        id: this.$route.params.detailId,
        quantity: this.form.quantity,
        price: this.form.price,
        discount1: this.form.discount1,
        discount2: this.form.discount2,
        description: this.form.description
      })
      if (res) {
        await this.$emit('showOrderWithDetail', this.headerInfo)
        // console.log(headerData)
        // if (headerData) {
        //   this.SHOW_DETAIL(this.$route.params.id)
        // }
        this.closePopup()
        const message = this.$t('Global.notification_Updated', { name: this.form.stockItem.name })
        this.notifyShow({ message })
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return
      if (this.isEdit) {
        await this.editDetailItem()
      } else {
        await this.addNewStock()
      }
    }, 'submitForm')
  },

  watch: {
    'form.stockItem' (val) {
      if (!val || this.isEdit || !this.headerInfo) return
      // this.form.taxId = val.taxId
      this.form.unitId = val.unitId
      this.getPriceQuoteInfo()
    },
    'form.unitId' (val) {
      if (!this.isEdit) this.getPriceQuoteInfo()
    }
  },

  async mounted () {
    await this.getTaxes()
    if (this.isEdit) {
      this.$wait.start('setUpdatingData')
      await this.getHeaderData()
      await this.setUpdatingData()
      this.$wait.end('setUpdatingData')
    } else {
      this.$wait.start('setUpdatingData')
      await this.getHeaderData()
      this.$wait.end('setUpdatingData')
    }
  }
}
</script>

<style lang="scss" scoped>

  .Form-item-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: $font-size-small;
    margin-bottom: 20px;

    > div {
      display: flex;
      margin-right: 20px;
      align-items: center;

      select {
        margin-bottom: -2px;
        background-color: $color-gray;
      }
    }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
      color: $color-light;
    }
    select.tax {
      max-width: 100px;
    }
  }

</style>
