var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [[_vm._v(_vm._s(_vm.$t("Transfer.addTransferButton")))]],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "Form",
            { staticClass: "Form", attrs: { id: "transfer-detail-form" } },
            [
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Transfer.senderStore"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("custom-search", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|verify_inventory",
                          expression: "'required|verify_inventory'",
                        },
                      ],
                      attrs: {
                        "component-name":
                          "search-transfer-request-select-from-store",
                        name: "selectSenderStore",
                        "data-vv-as": _vm.$t("Transfer.senderStore"),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("selectSenderStore"),
                        },
                        "on-search": _vm.getOptionsStore,
                        options: _vm.selectMiniStoreList,
                        label: "name",
                        loading: _vm.isSearchStoreLists,
                        searchAll: true,
                        withDebounce: false,
                      },
                      on: {
                        input: _vm.inputFromSelection,
                        resetOptions: _vm.resetOptions,
                      },
                      model: {
                        value: _vm.form.fromStoreId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "fromStoreId", $$v)
                        },
                        expression: "form.fromStoreId",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("selectSenderStore"),
                          expression: "veeErrors.has('selectSenderStore')",
                        },
                      ],
                      attrs: {
                        errorName: _vm.veeErrors.first("selectSenderStore"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Transfer.buyerStore"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("custom-search", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|verify_inventory",
                          expression: "'required|verify_inventory'",
                        },
                      ],
                      attrs: {
                        "component-name":
                          "search-transfer-request-select-buyer-store",
                        name: "selectBuyerStore",
                        "data-vv-as": _vm.$t("Transfer.buyerStore"),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("selectBuyerStore"),
                        },
                        "on-search": _vm.getOptionsToStore,
                        options: _vm.selectMiniToStoreList,
                        label: "name",
                        loading: _vm.isSearchStoreLists,
                        searchAll: true,
                        withDebounce: false,
                      },
                      on: {
                        input: _vm.inputToSelection,
                        resetOptions: _vm.resetOptions,
                      },
                      model: {
                        value: _vm.form.toStoreId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "toStoreId", $$v)
                        },
                        expression: "form.toStoreId",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("selectBuyerStore"),
                          expression: "veeErrors.has('selectBuyerStore')",
                        },
                      ],
                      attrs: {
                        errorName: _vm.veeErrors.first("selectBuyerStore"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Transfer.Transfer_Date"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("CustomDatepicker", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "verify_inventory",
                          expression: "'verify_inventory'",
                        },
                      ],
                      class: { "is-danger": _vm.veeErrors.has("transferDate") },
                      attrs: {
                        id: "transfer-request-detail-datepicker",
                        name: "transferDate",
                        "data-vv-as": _vm.$t("Transfer.Transfer_Date"),
                        pickerType: "manuel",
                        inputIconRight: "icon-datepicker-big",
                        inputIcon: "",
                        disabledStartDate: _vm.disableDate,
                      },
                      model: {
                        value: _vm.form.transferDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "transferDate", $$v)
                        },
                        expression: "form.transferDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.inventoryValidation,
                        expression: "inventoryValidation",
                      },
                    ],
                    staticClass: "Form-item-help is-danger",
                  },
                  [_vm._v(_vm._s(_vm.$t("Inventory.closedInventoryError")))]
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Despatches.detail_Popup_FormField_StockItemName")
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("custom-search", {
                      ref: "firstInput",
                      attrs: {
                        "component-name": "transfer-detail-stock-item",
                        name: "stockItem",
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_StockItemName"
                        ),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("stockItem"),
                        },
                        "on-search": _vm.getOptions,
                        options: _vm.selectOptions,
                        label: "name",
                        placeholder: _vm.$t(
                          "Despatches.detail_Popup_FormField_StockItemName_Placeholder"
                        ),
                        directSelectOption: _vm.directSelectOption,
                      },
                      on: { input: _vm.stockUnitSelection },
                      model: {
                        value: _vm.form.stockItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "stockItem", $$v)
                        },
                        expression: "form.stockItem",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("stockItem"),
                        expression: "veeErrors.has('stockItem')",
                      },
                    ],
                    staticClass: "Form-item-help is-danger",
                    attrs: {
                      id: "validate-message-transfer-detail-stock-item",
                    },
                  },
                  [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("ListsDespatch.detail_Popup_FormField_Quantity")
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customNumberInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                            min_value: 0,
                            max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                          },
                          expression:
                            "{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                        },
                      ],
                      attrs: {
                        name: "quantity",
                        error: _vm.veeErrors.has("quantity"),
                        "data-vv-as": _vm.$t(
                          "ListsDespatch.detail_Popup_FormField_Quantity"
                        ),
                        id: "input-transfer-request-detail-quantity",
                        isDelayInput: true,
                      },
                      model: {
                        value: _vm.form.quantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "quantity", $$v)
                        },
                        expression: "form.quantity",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.detail_Popup_FormField_BaseUnit"))
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                    _c("customSelectInput", {
                      attrs: {
                        name: "unitId",
                        optionData: _vm.filteredUnitList,
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_BaseUnit"
                        ),
                        error: _vm.veeErrors.has("unitId"),
                        id: "select-transfer-popup-baseunit",
                        optionIdName: "option-transfer-popup-baseunit",
                      },
                      model: {
                        value: _vm.form.unitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "unitId", $$v)
                        },
                        expression: "form.unitId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Transfer.Transfer_info"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customTextareaInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:128",
                          expression: "'max:128'",
                        },
                      ],
                      staticClass: "txt",
                      attrs: {
                        id: "input-transfer-header-info",
                        name: "info",
                        "data-vv-as": _vm.$t("Transfer.Transfer_info"),
                        error: _vm.veeErrors.has("info"),
                      },
                      model: {
                        value: _vm.form.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CustomCheckbox", {
            attrs: {
              id: "checkbox-request-transfer-detail-save-and-new",
              label: _vm.$t("Global.formSaveAndNewText", {
                form: _vm.$t("RequestedTransfer.detail_transferRequest"),
              }),
            },
            model: {
              value: _vm.saveAndNew,
              callback: function ($$v) {
                _vm.saveAndNew = $$v
              },
              expression: "saveAndNew",
            },
          }),
          _c(
            "Button",
            {
              staticClass: "m-top-15",
              attrs: {
                id: "finalize-transfer-request",
                primary: "",
                size: "large",
                variant: "full",
                iconName: "icon-btn-success",
                iconClass: "icon-btn-success",
                disabled: _vm.checkSubmitButton,
              },
              on: { click: _vm.onSubmitForm },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.saveSelection")))])]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }