<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :isGetDataLoading="$wait.is('fetchDirectDetailInfo')",
  :tableName="headerType === 0 ? $t('DirectOrders.removeDetailFormTitle') : $t('DirectOrders.removeOrderDetailFormTitle') ",
  loading="DeleteDirectOrderDetail",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'DirectOrdersDetailRemove',

  data () {
    return {
      fields: [{
        name: 'storeName',
        is_visible: true,
        short_title: this.$t('DirectOrders.tableStoreName'),
        title: this.$t('DirectOrders.tableStoreName')
      },
      {
        name: 'requestDate',
        is_visible: true,
        short_title: this.$t('DirectOrders.tableRequestDate'),
        title: this.$t('DirectOrders.tableRequestDate')
      },
      {
        name: 'stockItemName',
        is_visible: true,
        short_title: this.$t('DirectOrders.formFieldStockItemName'),
        title: this.$t('DirectOrders.formFieldStockItemName')
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('DirectOrders.tableQuantity'),
        title: this.$t('DirectOrders.tableQuantity')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('DirectOrders.tableUnitName'),
        title: this.$t('DirectOrders.tableUnitName')
      }],
      item: {},
      headerType: null
    }
  },

  async mounted () {
    this.headerType = this.$route.params.headerType
    const res = await this.fetchDirectDetailInfo({id: this.$route.params.detailId, headerType: this.headerType})
    const { directRequestDetail } = await res.data
    this.item = {
      ...directRequestDetail,
      requestDate: moment(directRequestDetail.requestDate).format('DD.MM.YYYY')
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('DirectOrders', [
      'DeleteDirectOrderDetail',
      'fetchDirectOrderDetail',
      'fetchDirectDetailInfo',
      'fetchDirectOrderInfo'
    ]),

    ...mapMutations('DirectOrders', [
      'SHOW_DETAIL'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      let result = await this.DeleteDirectOrderDetail(this.$route.params.detailId)
      if (result) {
        const message = this.$t('DirectOrders.deleteDetailSuccesMessage')
        await this.fetchDirectOrderDetail({headerId: this.$route.params.id, headerType: this.headerType})
        await this.fetchDirectOrderInfo(this.$route.params.id)
        this.notifyShow({ message })
        this.close()
      }
    }, 'itemRemoveDirectOrderDetail')
  }
}
</script>

<style lang="scss">
</style>
