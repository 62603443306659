<template lang="pug">

  Popup
    template(slot="title")
      div(v-if="!isEdit") {{ $t('Request.add_Request') }}
      div(v-else) {{ $t('Request.update_Request') }}

    template(slot="content")
      div
        p {{ $t('Request.formFieldDescription') }}

        .control
          customTextareaInput(
            id="request-header-form-description"
            v-model="description"
            v-validate="'max:128'"
            name="description"
            :data-vv-as="$t('Request.formFieldDescription')"
            :error="veeErrors.has('description')"
            rows="3"
            )

          showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

        p.requestFormItem(v-if="!isEdit") {{ $t('Request.select_type') }}

        Button.m-bottom-20.requestFormItem(
          seashell,
          v-for="(type, index) in requestTypes",
          variant="full"
          size="large"
          :id="`form-request-header-button-${index}`"
          v-if="checkPermission(type.permissionKey) && !isEdit"
          :key="type.value",
          :disabled="isCreatingData || veeErrors.has('description')",
          :isLoading="isCreatingData && type.value === selectedType",
          @click="createNewRequest(type.value)"
        )
          span {{ type.text }}

    template(slot="footer")
      Button.item(
        id="btn-update-request-header"
        primary
        size="medium"
        variant="full"
        @click="editRequestHeader"
        v-if="isEdit"
        :disabled="isUpdatingData || veeErrors.has('description')")
          span(v-show="!isUpdatingData") {{ $t('Request.update_Request') }}
          Loading(theme="disable", v-show="isUpdatingData")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'requestHeaderForm',

  data () {
    return {
      requestTypes: [
        {
          text: this.$t('Request.type_order'),
          permissionKey: 'RequestOrder',
          value: 2
        },
        {
          text: this.$t('Request.type_transfer'),
          permissionKey: 'RequestTransfer',
          value: 3
        }
      ],
      selectedType: null,
      description: null
    }
  },

  computed: {
    ...mapGetters('Settings', [
      'checkPermission'
    ]),

    isCreatingData () {
      return this.$wait.is(['editRequestHeader', 'createNewRequestHeader'])
    },

    isUpdatingData () {
      return this.$wait.is('updateRequestHeader')
    },

    isEdit () {
      return !!this.$route.params.id
    }

  },

  methods: {

    ...mapActions('Request', [
      'createRequestHeader',
      'updateRequestHeader'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    createNewRequest: vueWaitLoader(async function (value) {
      this.selectedType = value
      const res = await this.createRequestHeader({
        type: value,
        description: this.description
      })
      if (res) {
        this.$emit('showDrafts')
        await this.$emit('getRequestList')
        await this.$router.push({
          name: 'RequestDetailNew',
          params: {
            id: res.data.id,
            code: res.data.code,
            type: this.selectedType
          }
        })
      }
    }, 'createNewRequestHeader'),

    editRequestHeader: vueWaitLoader(async function () {
      const res = await this.updateRequestHeader({
        id: this.$route.params.id,
        description: this.description
      })
      if (res) {
        const message = this.$t('Request.request_update_message')
        this.notifyShow({ message })
        await this.$emit('getRequestList')
        this.close()
      }
    }, 'editRequestHeader')
  },

  async mounted () {
    if (this.isEdit) {
      this.description = this.$route.params.item.description
    }
  }
}
</script>

<style lang="scss" scoped>

  .requestFormItem {
    font-weight: normal;
    margin-top: 15px;
  }
</style>
