export function getDirectOrdersHeaderFields($t) {
  return [
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.directOrderNumber')
      },
      get title() {
        return $t('DirectOrders.directOrderNumber')
      }
    },
    {
      name: 'approvalStatus',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Status')
      },
      get title() {
        return $t('Dictionary.Status')
      }
    },
    {
      name: 'priceQuoteTotalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.totalCounts')
      },
      get title() {
        return $t('DirectOrders.totalCounts')
      }
    },
    {
      name: 'maxApprovalLevel',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Global.approvalLevel')
      },
      get title() {
        return $t('Global.approvalLevel')
      }
    },
    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}

export function getDirectOrdersDetailFields($t, $getConst) {
  return [
    { type: 'fixed',
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.tableStoreName')
      },
      get title() {
        return $t('DirectOrders.tableStoreName')
      }
    },
    {
      name: 'supplierName',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.tableSupplierName')
      },
      get title() {
        return $t('DirectOrders.tableSupplierName')
      }
    },
    {
      name: 'requestDate',
      type: 'date',
      tabIndex: false,
      editable: true,
      disabledStatus: row => row.headerType === 1,
      usableDate: {
        from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
        to: new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split('T')[0]
      },
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.tableRequestDate')
      },
      get title() {
        return $t('DirectOrders.tableRequestDate')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      style: 'min-width: 200px;',
      get short_title() {
        return $t('DirectOrders.formFieldStockItemName')
      },
      get title() {
        return $t('DirectOrders.formFieldStockItemName')
      }
    },
    {
      name: 'stockOnHandQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.stockOnHand')
      },
      get title() {
        return $t('DirectOrders.stockOnHand')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      editable: true,
      disabledStatus: row => row.approvalStatus > 0 && row.approvalStatus !== 3,
      validationRules: item => {
        return {
          required: item.isQuantityRequired, min_value: (item.isQuantityRequired) ? 1 : 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')
        }
      },
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.tableQuantity')
      },
      get title() {
        return $t('DirectOrders.tableQuantity')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.tableUnitName')
      },
      get title() {
        return $t('DirectOrders.tableUnitName')
      }
    },
    {
      name: 'priceQuoteUnitPrice',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.counts')
      },
      get title() {
        return $t('DirectOrders.counts')
      }
    },
    {
      name: 'priceQuoteTotalUnitPrice',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.totalUnitCounts')
      },
      get title() {
        return $t('DirectOrders.totalUnitCounts')
      }
    },
    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}
