import BaseClass from "@/utils/baseclass";
import moment from "moment";

export default class Orders extends BaseClass {
  addItem(item) {
    let newItem = new Order(item);
    this.list.push(newItem);
  }

  updateItem(item) {
    let findItem = this.findItem(item.id);
    if (findItem) {
      findItem.id = item.id;
      findItem.code = item.code;
      findItem.status = item.status;
      findItem.orderStatus = item.orderStatus;
      findItem.orderType = item.orderType;
      findItem.supplierIsZeroPriceAvailable = item.supplierIsZeroPriceAvailable;
      findItem.orderDate = item.orderDate;
    }
  }
}

export class Order {
  constructor(item) {
    this.id = item.id;
    this.accountingMessage = item.accountingMessage;
    this.accountingStatus = item.accountingStatus;
    this.grossTotalAmount = item.grossTotalAmount;
    this.netTotalAmount = item.netTotalAmount;
    this.orderDate = item.orderDate;
    this.orderNumber = item.orderNumber;
    this.orderType = item.orderType;
    this.storeId = item.storeId;
    this.storeName = item.storeName;
    this.supplierId = item.supplierId;
    this.supplierName = item.supplierName;
    this.taxTotalAmount = item.taxTotalAmount;
    this.totalUnitQuantityList = item.totalUnitQuantityList || [];
    this.showDetail = false;
    //     // this.detailList = item.orderDetailList.map(detail => {
    //   return {
    //     ...detail,
    //     headerId: item.id,
    //     supplierId: item.supplierId,
    //     date: item.orderDate
    //   }
    // })
    this.integrationStatusId = item.integrationStatusId;
    this.integrationStatusIsClosureStatus =
      item.integrationStatusIsClosureStatus;
    this.wmsStatus = item.wmsStatus;
    this.wmsMessage = item.wmsMessage;
    this.showAccountingStatus = false;
    this.integrationStatusName = item.integrationStatusName;
    this.supplierIsZeroPriceAvailable = item.supplierIsZeroPriceAvailable;
    this.description = item.description;
    this.detailList = [];
    this.status = !item.integrationStatusIsClosureStatus ? 1 : 2;
    this.createdOn =
      moment(item.createdOn).format("DD-MM-YYYY") +
      " / " +
      moment(item.createdOn)
        .add(new Date().getTimezoneOffset() / -60, "hours")
        .format("H:mm");
  }

  addDetails(detailList) {
    this.detailList = [];
    detailList.forEach((detail) => {
      this.detailList.push(
        new OrderDetail({
          ...detail,
          headerId: this.id,
          supplierId: this.supplierId,
          supplierName: this.supplierName,
        })
      );
    });
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id);
    if (findIndex > -1) {
      this.detailList.splice(findIndex, 1);
    }
  }

  findIndex(Id) {
    return this.detailList.findIndex((item) => item.id === Id);
  }

  findItem(Id) {
    return this.detailList.find((item) => item.id === Id);
  }

  get detailIsEmpty() {
    return this.detailList.length === 0;
  }
}

export class OrderDetail {
  constructor(detail) {
    this.id = detail.id;
    this.supplierId = detail.supplierId;
    this.supplierName = detail.supplierName;
    this.status = detail.status;
    this.stockItemId = detail.stockItemId;
    this.stockItemName = detail.stockItemName;
    this.unitName = detail.unitName;
    this.quantity = detail.quantity;
    this.price = detail.price;
    this.priceType = detail.priceType;
    this.discount1 = detail.discount1;
    this.discount2 = detail.discount2;
    this.taxAmount = detail.taxAmount;
    this.netAmount = detail.netAmount;
    this.grossAmount = detail.grossAmount;
    this.description = detail.description;
    this.headerId = detail.headerId;
    this.stockItemNumber = detail.stockItemNumber;
    this.supplierStockItemCode = detail.supplierStockItemCode;
  }
}
