var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [
              _vm._v(
                _vm._s(
                  _vm.headerType === 0
                    ? _vm.$t("DirectOrders.updateText")
                    : _vm.$t("DirectOrders.orderUpdateText")
                )
              ),
            ])
          : _c("div", [
              _vm._v(
                _vm._s(
                  _vm.headerType === 0
                    ? _vm.$t("DirectOrders.createText")
                    : _vm.$t("DirectOrders.orderCreateText")
                )
              ),
            ]),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "info" }, [
          _c("span", [_vm._v(_vm._s(_vm.$route.params.code))]),
        ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "selectType" },
                [
                  _c("SelectedButtons", {
                    attrs: { data: _vm.selectedTypes() },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$wait.is("setData")
            ? _c("Loading")
            : _c(
                "form",
                {
                  attrs: { id: "direct-orders-detail-form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("DirectOrders.formFieldStoreName"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          ref: "secondaryInput",
                          attrs: {
                            "component-name":
                              "select-directOrders-from-store-name",
                            name: "storeName",
                            "data-vv-as": _vm.$t(
                              "DirectOrders.formFieldStoreName"
                            ),
                            inputClass: {
                              "is-danger": _vm.veeErrors.has("storeName"),
                            },
                            "on-search": _vm.getOptionsStore,
                            options: _vm.selectMiniStoreList,
                            disabled: _vm.isEdit,
                            label: "name",
                            searchAll: true,
                            withDebounce: false,
                            placeholder: _vm.$t(
                              "DirectOrders.formFieldStoreNameUnSelected"
                            ),
                            loading: _vm.isSearchStoreLists,
                          },
                          on: {
                            input: _vm.inputSelection,
                            resetOptions: _vm.resetOptions,
                          },
                          model: {
                            value: _vm.form.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "storeId", $$v)
                            },
                            expression: "form.storeId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("storeName"),
                              expression: "veeErrors.has('storeName')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("storeName"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("DirectOrders.formFieldSupplierName"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            name: "supplier",
                            componentName: "form-direct-order-supplier",
                            "data-vv-as": _vm.$t(
                              "DirectOrders.formFieldSupplierName"
                            ),
                            inputClass: {
                              "is-danger": _vm.veeErrors.has("supplier"),
                            },
                            "on-search": _vm.getOptionsSupplier,
                            options: _vm.selectOptionSupplier,
                            label: "name",
                            disabled: _vm.isEdit,
                            placeholder: _vm.$t(
                              "DirectOrders.formFieldSupplierNamePlaceholder"
                            ),
                          },
                          model: {
                            value: _vm.form.supplier,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "supplier", $$v)
                            },
                            expression: "form.supplier",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("supplier"),
                            expression: "veeErrors.has('supplier')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("supplier")))]
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("DirectOrders.formFieldDate"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("custom-date-picker", {
                          attrs: {
                            name: "RequestDate",
                            id: "request-form-rdate",
                            "data-vv-as": _vm.$t("DirectOrders.formFieldDate"),
                            pickerType: "manuel",
                            inputIcon: "",
                            inputIconRight: "icon-datepicker-big",
                            disabledStartDate: _vm.disableDate,
                          },
                          model: {
                            value: _vm.form.requestDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "requestDate", $$v)
                            },
                            expression: "form.requestDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.type === 0
                    ? [
                        _c("div", { staticClass: "Form-item required" }, [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("DirectOrders.formFieldStockItemName")
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("custom-search", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { required: _vm.type === 0 },
                                    expression: "{'required': type === 0}",
                                  },
                                ],
                                ref: "stockInput",
                                class: {
                                  "is-danger": _vm.veeErrors.has("stockItem"),
                                },
                                attrs: {
                                  name: "stockItem",
                                  "data-vv-as": _vm.$t(
                                    "DirectOrders.formFieldStockItemName"
                                  ),
                                  disabled: _vm.isEdit,
                                  "on-search": _vm.getOptionsStock,
                                  options: _vm.selectOptionsStock,
                                  label: "name",
                                  placeholder: _vm.$t(
                                    "DirectOrders.formFieldStockItemNamePlaceholder"
                                  ),
                                  directSelectOption: _vm.directSelectOption,
                                },
                                on: { input: _vm.fetchStockItems },
                                model: {
                                  value: _vm.form.stockItem,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "stockItem", $$v)
                                  },
                                  expression: "form.stockItem",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("stockItem"),
                                  expression: "veeErrors.has('stockItem')",
                                },
                              ],
                              staticClass: "Form-item-help is-danger",
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "Form-item required" },
                          [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("DirectOrders.formFieldQuantity"))
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        required: true,
                                        min_value: _vm.form.isQuantityRequired
                                          ? 1
                                          : 0,
                                        max: _vm.$getConst(
                                          "MAX_DIGITS_FOR_QUANTITY"
                                        ),
                                      },
                                      expression:
                                        "{required: true, min_value: (form.isQuantityRequired) ? 1 : 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                                    },
                                  ],
                                  attrs: {
                                    id: "directOrders-form-quantity",
                                    name: "quantity",
                                    "data-vv-as": _vm.$t(
                                      "DirectOrders.formFieldQuantity"
                                    ),
                                    error: _vm.veeErrors.has("quantity"),
                                  },
                                  model: {
                                    value: _vm.form.quantity,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "quantity", $$v)
                                    },
                                    expression: "form.quantity",
                                  },
                                }),
                                _vm.form.stockItem
                                  ? _c(
                                      "div",
                                      { staticClass: "Form-item-info" },
                                      [
                                        _c("div", [
                                          _c("div", { staticClass: "text" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "Waste.detail_TableColumn_StockOnHandQuantity"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("div", { staticClass: "value" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.stockItemInfo
                                                  .baseUnitQuantity
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("quantity"),
                                  expression: "veeErrors.has('quantity')",
                                },
                              ],
                              attrs: {
                                errorName: _vm.veeErrors.first("quantity"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "Form-item required" }, [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("DirectOrders.formFieldUnitName"))
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control form-item-select" },
                            [
                              _c("Icon", {
                                attrs: { name: "icon-down-arrow" },
                              }),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.unitId,
                                      expression: "form.unitId",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|is_not:-1",
                                      expression: "'required|is_not:-1'",
                                    },
                                  ],
                                  staticClass: "select",
                                  class: {
                                    "is-danger": _vm.veeErrors.has("unitId"),
                                  },
                                  attrs: {
                                    id: "select-directOrders-popup-baseunit",
                                    name: "unitId",
                                    disabled: _vm.isEdit,
                                    "data-vv-as": _vm.$t(
                                      "DirectOrders.formFieldUnitName"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.form,
                                        "unitId",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.isEdit
                                    ? _c(
                                        "option",
                                        {
                                          attrs: {
                                            value: "-1",
                                            id: "option-directOrders-popup-baseunit-unselected",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "DirectOrders.formFieldUnitNameUnSelected"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._l(_vm.filteredUnitList, function (unit) {
                                    return _c(
                                      "option",
                                      {
                                        key: unit.id,
                                        attrs: {
                                          id: `option-directOrders-popup-baseunit-${unit.name}`,
                                        },
                                        domProps: { value: unit.id },
                                      },
                                      [_vm._v(_vm._s(unit.name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("unitId"),
                                  expression: "veeErrors.has('unitId')",
                                },
                              ],
                              staticClass: "Form-item-help is-danger",
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("unitId")))]
                          ),
                        ]),
                      ]
                    : [
                        _c(
                          "div",
                          {
                            staticClass: "Form-item",
                            class: _vm.isOnlyListUsage ? "required" : "",
                          },
                          [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("DirectOrders.formFieldList"))
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("custom-search", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        required: _vm.isOnlyListUsageCalculate,
                                      },
                                      expression:
                                        "{required: isOnlyListUsageCalculate}",
                                    },
                                  ],
                                  ref: "listInput",
                                  attrs: {
                                    componentName: "form-direct-order-list",
                                    name: "list",
                                    inputClass: {
                                      "is-danger": _vm.veeErrors.has("list"),
                                    },
                                    disabled: !_vm.form.supplier,
                                    "on-search": _vm.getOptionsDespatchList,
                                    options: _vm.selectOptionsDespatchList,
                                    label: "name",
                                    searchAll: true,
                                    placeholder: _vm.$t(
                                      "DirectOrders.formFieldListPlaceholder"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.addList(_vm.form.list)
                                    },
                                  },
                                  model: {
                                    value: _vm.form.list,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "list", $$v)
                                    },
                                    expression: "form.list",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("list"),
                                  expression: "veeErrors.has('list')",
                                },
                              ],
                              attrs: { errorName: _vm.veeErrors.first("list") },
                            }),
                            _c(
                              "div",
                              { staticClass: "selected-items" },
                              _vm._l(_vm.lists, function (item, index) {
                                return _c(
                                  "Button",
                                  {
                                    key: index,
                                    attrs: {
                                      seashell: "",
                                      id: `selected-list-item-${index}`,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeListItem(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c("Icon", {
                                      staticClass: "m-left-5",
                                      attrs: {
                                        name: "icon-popup-close",
                                        id: `selected-list-item-delete-${index}`,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                  _vm.isEdit || _vm.type === 0
                    ? _c(
                        "div",
                        { staticClass: "Form-item" },
                        [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("DirectOrders.formFieldDescription")
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("customTextareaInput", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "max:512",
                                    expression: "'max:512'",
                                  },
                                ],
                                attrs: {
                                  name: "description",
                                  "data-vv-as": _vm.$t(
                                    "DirectOrders.formFieldDescription"
                                  ),
                                  error: _vm.veeErrors.has("description"),
                                },
                                model: {
                                  value: _vm.form.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "description", $$v)
                                  },
                                  expression: "form.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("description"),
                                expression: "veeErrors.has('description')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("description"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-request-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("DirectOrders.moduleTitle"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                id: "btn-request-popup-submit",
                type: "submit",
                form: "direct-orders-detail-form",
                primary: "",
                variant: "full",
                size: "medium",
                isLoading: _vm.isRunAction,
                disabled:
                  _vm.isRunAction || (_vm.type === 1 && _vm.lists.length === 0),
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("DirectOrders.updateText"))),
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("DirectOrders.createText"))),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }