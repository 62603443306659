import DirectOrders from '../index'
import DirectOrdersHeader from '../popup/directOrderHeaderForm'
import DirectOrdersForm from '../popup/directOrderForm'
import DirectOrdersRemove from '../popup/removeDirectOrderHeader'
import DirectOrderDetailRemove from '../popup/removeDirectOrderDetail'

import Requests from '../requests'
import RequestHeaderForm from '../popup/requestsHeaderForm'
import RequestDetailForm from '../popup/requestsDetailForm'
import RequestHeaderRemove from '../popup/removeRequestHeader'
import RequestDetailRemove from '../popup/removeRequestDetail'

import RequestedOrder from '../requestedOrder'
import OrderRequestDetailForm from '../popup/addOrderRequestDetail'

import RequestedTransfer from '../requestedTransfer'
import TransferRequestDetailForm from '../popup/addTransferRequestDetail'

import ApprovedOrder from '../approvedOrder'
import ApprovedOrderHeaderForm from '../popup/approvedOrderHeaderForm'
import ApprovedOrderDetailForm from '../popup/approvedOrderDetailForm'

export default [
  // direct orders
  {
    name: 'AllOrders',
    path: '/requests',
    component: DirectOrders,
    meta: {
      main_menu: true,
      slug: 'request',
      category: 'Buy',
      permissionKey: 'RequestMenu',
      tabPermissionKey: 'DirectOrder',
      page: 'requests'
    },
    children: [
      {
        name: 'DirectOrdersRemove',
        path: '/requests/remove/:id/:code',
        component: DirectOrdersRemove
      },
      {
        name: 'DirectOrdersNew',
        path: '/requests/new',
        component: DirectOrdersHeader
      },
      {
        name: 'DirectOrdersUpdate',
        path: '/requests/edit/:id',
        component: DirectOrdersHeader,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'DirectOrdersDetailNew',
        path: '/requests/detail/new/:id/:code',
        component: DirectOrdersForm
      },
      {
        name: 'DirectOrdersDetailEdit',
        path: '/requests/detail/edit/:id/:code/:detailId/:headerType',
        component: DirectOrdersForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'DirectOrdersDetailRemove',
        path: '/requests/detail/remove/:id/:detailId',
        component: DirectOrderDetailRemove
      }
    ]
  },
  // request
  {
    path: '/requests/lists',
    name: 'Requests',
    component: Requests,
    meta: {
      main_menu: false,
      tabPermissionKey: 'Request',
      page: 'requests'
    },
    children: [
      {
        path: '/requests/lists/header/new',
        name: 'RequestHeaderForm',
        component: RequestHeaderForm
      },
      {
        path: '/requests/lists/detail/new/:id/:code/:type',
        name: 'RequestDetailNew',
        component: RequestDetailForm
      },
      {
        path: '/requests/lists/detail/remove/:id/:detailId',
        name: 'RequestDetailRemove',
        component: RequestDetailRemove
      },
      {
        path: '/requests/lists/detail/:id/:code/:detailId',
        name: 'RequestDetailUpdate',
        component: RequestDetailForm
      },
      {
        path: '/requests/lists/header/remove/:id',
        name: 'RequestHeaderRemove',
        component: RequestHeaderRemove
      },
      {
        path: '/requests/lists/header/update/:id/',
        name: 'RequestHeaderUpdate',
        component: RequestHeaderForm
      }
    ]
  },
  // requests-order
  {
    path: '/requests/requests-order',
    name: 'RequestsOrder',
    component: RequestedOrder,
    meta: {
      main_menu: false,
      tabPermissionKey: 'RequestApprove',
      page: 'requests'
    },
    children: [
      {
        path: '/requests/requests-order/add-detail',
        name: 'OrderRequestDetailForm',
        component: OrderRequestDetailForm
      }
    ]
  },
   // requests-transfers
  {
    path: '/requests/requests-transfer',
    name: 'RequestsTransfer',
    component: RequestedTransfer,
    meta: {
      main_menu: false,
      tabPermissionKey: 'TransferRequest',
      page: 'requests'
    },
    children: [
      {
        path: '/requests/requests-transfer/add-detail',
        name: 'TransferRequestDetailForm',
        component: TransferRequestDetailForm
      }
    ]
  },
  // approved orders
  {
    path: '/requests/orders',
    name: 'Orders',
    component: ApprovedOrder,
    meta: {
      main_menu: false,
      tabPermissionKey: 'Order',
      page: 'requests'
    },
    children: [
      {
        name: 'OrdersEdit',
        path: 'edit/:id',
        component: ApprovedOrderHeaderForm
      },
      {
        name: 'orderAddStock',
        path: 'add-stock/:id',
        component: ApprovedOrderDetailForm
      },
      {
        name: 'OrderDetailEdit',
        path: 'detail/edit/:id/:detailId',
        component: ApprovedOrderDetailForm,
        props: {
          status: 'edit'
        }
      }
    ]
  }

]
