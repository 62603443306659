import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    Transfers: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    transfers: state => state.Transfers,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },
  actions: {
    async getAllRequestedTransfer({ commit, state }, payload = {fromStoreList: [], toStoreList: []}) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'getAllRequestedTransfer',
        methodName: 'getAllRequestedTransfer',
        method: () => {
          return HTTP.post('Request/transfer/all', {
            ...payload,
            PageSize: payload.pageSize,
            pageNumber: PageNumber
          })
        },
        success: result => {
          commit('setTransferList', result.data)
          return result
        }
      })
      return results
    },
    async approvedTransferRequest({ commit }, approvedRequestList) {
      const results = await requestHandler({
        title: 'approvedTransferRequest',
        methodName: 'approvedTransferRequest',
        method: () => {
          return HTTP.post('Request/approve/transfer', { approvedRequestList })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    setTransferList(state, list) {
      state.Transfers = list.requestedTransferList
      state.Page.number = list.pageNumber
      state.Page.size = list.pageSize
      state.Page.total = list.totalCount
    }
  }
}
