<template lang="pug">
 Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('Global.editData', { field: $t('DirectOrders.moduleTitleWithPrefix') }) }}
      div(v-else) {{ $t('Global.addData', { field: $t('DirectOrders.moduleTitle') }) }}

    template(slot="content")

      form.Form#direct-orders-header-form(@submit.prevent="onSubmitForm")

        .Form-item
          label.Form-item-label {{ $t('DirectOrders.formFieldDescription') }}
          .control

            customTextareaInput(
              id="direct-orders-header-form-description"
              v-model="form.description"
              v-validate="'max:512'"
              name="description"
              :data-vv-as="$t('DirectOrders.formFieldDescription')"
              :error="veeErrors.has('description')"
            )

          showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
          )

    template(slot="footer")
      Button.uppercase#direct-orders-header-form-action-button(
        primary,
        type="submit"
        size="large"
        variant="full"
        form="direct-orders-header-form",
        :isLoading="isRunAction",
        :disabled="isRunAction"
      )

        span(v-if="isEdit") {{ $t('Global.editData', { field: $t('DirectOrders.moduleTitleWithPrefix') }) }}
        span(v-else) {{ $t('Global.addData', { field: $t('DirectOrders.moduleTitle') }) }}
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'DirectOrdersHeaderForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      form: {
        description: null
      }
    }
  },
  computed: {
    isRunAction () {
      return this.$wait.is(['createDirectOrderHeader', 'updateDirectOrderHeader', 'submitForm'])
    },
    isEdit () {
      return this.status === 'edit'
    }
  },
  methods: {
    ...mapActions('DirectOrders', [
      'fetchDirectOrderHeaderAll',
      'fetchDirectOrderInfo',
      'fetchDirectOrderDetail',
      'createDirectOrderHeader',
      'updateDirectOrderHeader'
    ]),

    ...mapMutations('DirectOrders', ['SHOW_DETAIL']),

    ...mapActions('Notify', ['notifyShow']),
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    async newHeader () {
      const res = await this.createDirectOrderHeader(this.form)
      if (res) {
        const message = this.$t('DirectOrders.createSuccesMessage')
        this.notifyShow({ message })
        this.$emit('showDrafts')
        await this.$emit('getDirectOrdersList')
        this.$router.push({
          name: 'DirectOrdersDetailNew',
          params: {
            id: res.data.id,
            code: res.data.code,
            headerType: 0
          }
        })
      }
    },
    async editHeader () {
      const res = await this.updateDirectOrderHeader({
        ...this.form,
        id: this.$route.params.id
      })
      if (res) {
        const message = this.$t('DirectOrders.updateSuccesMessage')
        this.notifyShow({ message })
        const headerData = await this.$emit('getDirectOrdersList')
        const detailData = await this.fetchDirectOrderDetail({headerId: this.$route.params.id, headerType: 0})
        if (headerData && detailData) {
          this.SHOW_DETAIL({
            headerId: this.$route.params.id,
            headerType: 0,
            data: detailData.data
          })
        }
        this.close()
      }
    },
    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return
      if (this.isEdit) {
        await this.editHeader()
      } else {
        await this.newHeader()
      }
    }, 'submitForm')
  },

  async mounted () {
    if (this.isEdit) {
      const { data } = await this.fetchDirectOrderInfo(this.$route.params.id)
      this.form = {
        ...data.directRequestHeader
      }
    }
  }
}
</script>
