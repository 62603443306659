<template lang="pug">
  Popup
    template(slot="title")
      template {{ $t('Orders.createOrdersButton') }}

    template(slot="content")
      Form.Form(
        id="orders-detail-form"
      )  
        .Form-item.required
          label.Form-item-label
            | {{ $t('Orders.table_fieldStore') }}
          
          .control

            custom-search(
              component-name="search-order-request-select-store"
              name="selectStore"
              v-model="form.storeId"
              v-validate="'required'",
              :data-vv-as="$t('Orders.table_fieldStore')"
              :inputClass="{ 'is-danger': veeErrors.has('selectStore') }",
              :on-search="getOptionsStore"
              :options="selectMiniStoreList"
              label="name"
              :loading="isSearchStoreLists"
              :searchAll="true"
              :withDebounce="false"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('selectStore')"
              :errorName="veeErrors.first('selectStore')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Orders.table_fieldDate') }}
          .control.form-item-select
            CustomDatepicker(
              id="order-request-detail-datepicker"
              name="orderDate",
              v-model="form.orderDate",
              :class="{ 'is-danger': veeErrors.has('orderDate') }"
              :data-vv-as="$t('Orders.table_fieldDate')",
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon=""
              :disabledStartDate="disableDate")
          p.Form-item-help.is-danger(
            v-show="inventoryValidation"
          ) {{ $t('Inventory.closedInventoryError') }}
        
        .Form-item.required
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
              component-name="order-detail-stock-item"
              ref="firstInput"
              name="stockItem"
              v-model="form.stockItem"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StockItemName')"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem')}"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              :placeholder="$t('Despatches.detail_Popup_FormField_StockItemName_Placeholder')"
              :directSelectOption="directSelectOption"
              @input="stockUnitSelection"
            )

          p.Form-item-help.is-danger(
            id="validate-message-order-detail-stock-item"
            v-show="veeErrors.has('stockItem')"
          ) {{ veeErrors.first('stockItem') }}

        .Form-item.required
          label.Form-item-label {{ $t('ListsDespatch.detail_Popup_FormField_Quantity') }}
          .control
            customNumberInput(
              name="quantity"
              :error="veeErrors.has('quantity')"
              :data-vv-as="$t('ListsDespatch.detail_Popup_FormField_Quantity')"
              v-validate="{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              id="input-order-request-detail-quantity"
              v-model="form.quantity"
              :isDelayInput="true"
            )

        .Form-item.required
          label.Form-item-label
            | {{ $t('Despatches.detail_Popup_FormField_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            customSelectInput(
              name="unitId"
              :optionData="filteredUnitList"
              v-model="form.unitId"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_BaseUnit')"
              :error="veeErrors.has('unitId')"
              id="select-order-popup-baseunit"
              optionIdName="option-order-popup-baseunit"
            )

        .Form-item
          label.Form-item-label {{ $t('Orders.info') }}
          .control
            customTextareaInput.txt(
              id="input-order-detail-info"
              name="info"
              :data-vv-as="$t('Orders.info')"
              v-validate="'max:128'"
              v-model="form.description"
              :error="veeErrors.has('info')"
            )
    template(slot="footer")
      CustomCheckbox(
        id="checkbox-request-order-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Orders.detail_orderRequest') })"
        v-model="saveAndNew")
      Button.m-top-15(
      id="finalize-order-request",
      primary
      size="large"
      variant="full"
      iconName="icon-btn-success",
      iconClass="icon-btn-success"
      :disabled="checkSubmitButton"
      @click="onSubmitForm")
        span {{ $t('EInvoice.saveSelection') }}
              
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import CheckInventoryMixin from '@/mixins/validateInventory'
import customSelect from '@/view/global/custom-select'
import { vueWaitLoader } from '@/utils/baseOperations'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'

export default {
  name: 'OrderRequestDetailForm',
  mixins: [CheckInventoryMixin, getQuantityFromBarcode],
  components: {
    customSelect
  },

  data () {
    return {
      disableDate: {
        to: moment().hour(-1).minute(0)._d
      },
      form: {
        storeId: {
          id: '-1',
          name: ''
        },
        quantity: null,
        unitId: '',
        stockItem: null,
        orderDate: new Date(),
        description: null
      },
      selectOptions: [],
      selectedStock: {},
      selectMiniStoreList: [],
      defaultStoreId: -1,
      defaultStoreName: '',
      saveAndNew: true
    }
  },
  computed: {
    ...mapGetters('Stores', [
      'miniStoreList',
      'sortedStoreList'
    ]),
     ...mapGetters('Settings', [
      'permissions'
    ]),
    ...mapGetters('Units', ['UnitList', 'findUnit', 'getRelatedUnitList']),

    checkInventoryParams () {
      return {
        storeId: [this.form.storeId],
        inventoryDate: moment(this.form.orderDate).format('YYYY-MM-DD')
      }
    },
    checkSubmitButton () {
      if (this.form.storeId === '-1' || !this.form.quantity || this.form.unitId === '-1' || !this.form.stockItem || !this.form.orderDate) return true
      else return false
    },
    directSelectOption () {
      return this.isBarcodeSearch
        ? this.selectOptions[0]
        : {}
    },
    directSelectOptionStore () {
      if (this.isStoreSearch) return 
      return this.selectMiniStoreList[this.selectMiniStoreList.findIndex(item => item.id === this.form.storeId)]
    },
    isStoreSearch () {
      return this.selectMiniStoreList.length === 1
    },
    isBarcodeSearch () {
      return this.selectOptions.length === 1 &&
        this.selectOptions[0].matchType === 2
    },
    
    filteredUnitList () {
      if (!this.form.stockItem) return []
      const baseUnitId = this.findUnit(this.form.stockItem.unitId)?.baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },
  },

  async mounted () {
    await this.fetchUser().then(res => {
      if (res) {
        this.defaultStoreId = res.status === 200 ? res.data.user.defaultStoreId : -1
        this.defaultStoreName = res.status === 200 ? res.data.user.defaultStoreName : ''
        this.form.storeId = { id: this.defaultStoreId, name: this.defaultStoreName }
      }
    }) // set default store in selectbox
    this.selectMiniStoreList = this.miniStoreList
  },

  methods: {

    ...mapActions('Settings', [
      'fetchUser'
    ]),

    ...mapActions('Stores', [
      'getMiniStoreList',
      'fetchStoreByName'
    ]),

    ...mapActions('Stock', ['searchStockItem', 'getStockItem']),

    ...mapActions('Request', [
      'createRequestDetailWithoutHeader'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    resetForm () {
      this.form.storeId.id = this.saveAndNew ? this.form.storeId.id : this.defaultStoreId
      this.form.storeId.name = this.saveAndNew ? this.form.storeId.name : this.defaultStoreName
      this.form.unitId = ''
      this.form.description = null
      this.form.orderDate = this.saveAndNew ? this.form.orderDate : new Date()
      this.form.stockItem = null
      this.form.quantity = null
      this.selectMiniStoreList = []
    },

    async resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    inputSelection (data) {
      if (data.length !== 0) {
        this.form.storeId.id = data.id
        this.form.storeId.name = data.name
      }
    },


    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },

    getOptions (search) {
      const decoratedValue = this.getWeightedBarcode(search)
      this.searchStockItem({ Text: decoratedValue, types: [1, 2, 3] })
        .then(res => {
          this.selectOptions = res.data.items
          if (this.isBarcodeSearch) {
            const unitId = res.data.items[0].unitId
            const parsedBarcode = this.getQuantityFromBarcode(search, unitId)
            if (parsedBarcode.isWeighted) {
              this.form.quantity = parsedBarcode.quantity
            }
          }
        })
    },

    async stockUnitSelection (option) {
      if (!option) return
      await this.setUnit(option.unitId)
    },
    setUnit (unitId) {
      this.form.unitId = unitId
    },

    onSubmitForm: vueWaitLoader(async function () {
      const payload = {
        requestType: 2,
        storeId: this.form.storeId.id,
        stockItemId: this.form.stockItem.id,
        unitId: this.form.unitId,
        quantity: this.form.quantity,
        requestDate: moment(this.form.orderDate).format('YYYY-MM-DD'),
        description: this.form.description
      }
      await this.createRequestDetailWithoutHeader(payload).then(res => {
        const message = this.$t('Orders.ordersAddedSuccessfully')
        if (!res) return
        this.notifyShow({ message })
        if (!this.saveAndNew) this.close()
        this.$emit('getList')
        this.resetForm()
      })
    }, 'onSubmitFormOrderHeader')
  }
}
</script>