<template lang="pug">
Popup

    template(slot="title")
      div {{ $t('Global.editData', { field: $t('Orders.moduleNameWithPrefix') }) }}

    template(slot="detail")
      Loading(v-if="isFetchingHeaderInfo")
      .m-top-10.font-weight-bold(v-else)
        span {{ form.orderNumber }}

    template(slot="content")
      Loading(v-if="isFetchingHeaderInfo")
      form.Form(
        v-else,
        id="approved-order-form-header",
        @submit.prevent="onSubmitForm"
      )

        .Form-item
          .Form-item-info-multiple

            .Form-item-info-multiple-row
              .text {{ $t('Orders.order_header_supplier') }}
              .value {{ form.supplier.name }}

            .Form-item-info-multiple-row
              .text {{ $t('Orders.order_header_date') }}
              .value {{ form.orderDate | dateFormat('YYYY-MM-DD') }}

            .Form-item-info-multiple-row

              .text {{ $t('Orders.order_header_store') }}
              .value {{ form.store.name }}

            .Form-item-info-multiple-row
              .text {{ $t('Orders.order_header_totalAmount') }}
              .value {{ form.netTotalAmount }}

            .Form-item-info-multiple-row
              .text {{ $t('Orders.order_header_grossAmount') }}
              .value {{ form.grossTotalAmount }}

            .Form-item-info-multiple-row

              .text {{ $t('Orders.order_header_taxAmount') }}
              .value {{ form.taxTotalAmount }}

        .Form-item
          label.Form-item-label {{ $t('Orders.order_header_date') }}
          .control.form-item-select
            CustomDatepicker(
              name="orderDate",
              :data-vv-as="$t('Orders.order_header_date') ",
              id="datepicker-approved-order-header-edit"
              v-model="form.orderDate"
              :class="{ 'is-danger': veeErrors.has(`orderDate`) }"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabledStartDate="disableDate")

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('orderDate')"
          ) {{ veeErrors.has('orderDate') }}

        .Form-item
          label.Form-item-label {{ $t('Waste.form_description') }}
          .control
            textarea.txt(
            name="description"
            :data-vv-as="$t('Waste.form_description')"
            v-model="form.description"
            id="textarea-approved-order-header-edit"
            :class="{ 'is-danger': veeErrors.has('description')}",
            rows="5"
            v-validate="'max:512'",
            type="text")

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('description')"
            ) {{ veeErrors.first('description') }}

    template(slot="footer")
      Button.uppercase(
        primary
        size="large"
        type="submit"
        variant="full"
        id="button-approved-order-form-header-action",
        form="approved-order-form-header",
        :disabled="isRunAction",
        :isLoading="isRunAction"
      )
        span {{ $t('Global.editData', { field: $t('Orders.moduleNameWithPrefix') }) }}
</template>

<script>

import { mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'approvedOrderHeaderForm',
  data () {
    return {
      form: {
        description: ''
      },
      headerInfo: {},
      disableDate: {
        to: new Date()
      }
    }
  },
  computed: {
    isRunAction () {
      return this.$wait.is('updateApprovedOrder')
    },
    isFetchingHeaderInfo () {
      return this.$wait.is('fetchingHeaderData')
    }
  },
  methods: {
    ...mapActions('Orders', ['updateApprovedOrder', 'fetchApprovedOrderHeader']),
    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('Orders', ['HIDE_DETAIL', 'SHOW_DETAIL']),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return
      const res = await this.updateApprovedOrder({
        id: this.headerInfo.id,
        ...this.form
      })
      if (res) {
        const message = this.$t('Global.successUpdateMessage', { field: this.$t('Orders.moduleName') })
        const headerData = await this.$emit('getOrdersHeaderAll')
        this.closePopup()
        this.notifyShow({ message })
        if (headerData) {
          this.SHOW_DETAIL(this.headerInfo)
        }
      }
    }, 'submitForm'),

    async setHeaderData () {
      const res = await this.fetchApprovedOrderHeader(this.$route.params.id)
      if (res) {
        this.headerInfo = res.data.orderHeader
        this.form = {
          description: this.headerInfo.description,
          supplier: {
            id: this.headerInfo.supplierId,
            name: this.headerInfo.supplierName
          },
          store: {
            id: this.headerInfo.storeId,
            name: this.headerInfo.storeName
          },
          orderDate: this.headerInfo.orderDate,
          orderNumber: this.headerInfo.orderNumber,
          grossTotalAmount: this.headerInfo.grossTotalAmount,
          taxTotalAmount: this.headerInfo.taxTotalAmount,
          netTotalAmount: this.headerInfo.netTotalAmount
        }
      }
    }

  },
  async beforeMount () {
    this.$wait.start('fetchingHeaderData')
    await this.setHeaderData()
    this.$wait.end('fetchingHeaderData')
  }
}
</script>

<style lang="scss" scoped>

  .Form-item-info-multiple {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    flex-wrap: wrap;
    margin-top: 17px;
    &-row {
      display: flex;
      justify-content: space-between;
      border: solid $color-gray 1px;
      width: 100%;
      padding: 17px 10px;
    }

    &-row:first-child {
        border-bottom: none;
      }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }
</style>
