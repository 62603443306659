<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ headerType === 0 ? $t('DirectOrders.updateText') : $t('DirectOrders.orderUpdateText')  }}
      div(v-else) {{ headerType === 0 ? $t('DirectOrders.createText') : $t('DirectOrders.orderCreateText') }}

    template(slot="detail")
      .info
        span {{ $route.params.code }}

    template(slot="content")
      .selectType(v-if="!isEdit")
        SelectedButtons(v-model="type", :data="selectedTypes()")
      Loading(v-if="$wait.is('setData')")
      form(id="direct-orders-detail-form",
        v-else,
        @submit.prevent="onSubmitForm")
        .Form-item.required
          label.Form-item-label
            | {{$t('DirectOrders.formFieldStoreName') }}
          .control.form-item-select
            custom-search(
              ref="secondaryInput"
              component-name="select-directOrders-from-store-name"
              name="storeName",
              v-model="form.storeId",
              :data-vv-as="$t('DirectOrders.formFieldStoreName')",
              v-validate="'required|is_not:-1'",
              :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
              :on-search="getOptionsStore",
              :options="selectMiniStoreList",
              :disabled="isEdit"
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('DirectOrders.formFieldStoreNameUnSelected')"
              :loading="isSearchStoreLists"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
          )
        .Form-item.required
          label.Form-item-label {{ $t('DirectOrders.formFieldSupplierName') }}
          .control
            custom-search(
            name="supplier",
            componentName="form-direct-order-supplier"
            v-model="form.supplier",
            :data-vv-as="$t('DirectOrders.formFieldSupplierName')",
            v-validate="'required'",
            :inputClass="{ 'is-danger': veeErrors.has('supplier') }",
            :on-search="getOptionsSupplier",
            :options="selectOptionSupplier",
            label="name",
            :disabled="isEdit",
            :placeholder="$t('DirectOrders.formFieldSupplierNamePlaceholder')")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('supplier')"
          ) {{ veeErrors.first('supplier') }}

        .Form-item.required
          label.Form-item-label {{ $t('DirectOrders.formFieldDate') }}
          .control.form-item-select
            custom-date-picker(
            name="RequestDate",
            id="request-form-rdate"
            :data-vv-as="$t('DirectOrders.formFieldDate') ",
            v-model="form.requestDate",
            pickerType="manuel",
            inputIcon="",
            inputIconRight="icon-datepicker-big",
            :disabledStartDate="disableDate")

        template(v-if="type === 0")
          .Form-item.required
            label.Form-item-label {{ $t('DirectOrders.formFieldStockItemName') }}
            .control
              custom-search(
                name="stockItem"
                ref="stockInput"
                v-model="form.stockItem"
                :data-vv-as="$t('DirectOrders.formFieldStockItemName')"
                v-validate="{'required': type === 0}"
                :class="{ 'is-danger': veeErrors.has('stockItem') }"
                :disabled="isEdit",
                :on-search="getOptionsStock"
                :options="selectOptionsStock"
                label="name"
                :placeholder="$t('DirectOrders.formFieldStockItemNamePlaceholder')"
                :directSelectOption="directSelectOption"
                @input="fetchStockItems"
              )

            p.Form-item-help.is-danger(
            v-show="veeErrors.has('stockItem')"
            ) {{ veeErrors.first('stockItem') }}

          .Form-item.required
            label.Form-item-label {{ $t('DirectOrders.formFieldQuantity') }}
            .control
              customNumberInput(
                id="directOrders-form-quantity"
                name="quantity"
                :data-vv-as="$t('DirectOrders.formFieldQuantity')"
                v-model="form.quantity"
                v-validate="{required: true, min_value: (form.isQuantityRequired) ? 1 : 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
                :error="veeErrors.has('quantity')"
              )

              .Form-item-info(v-if="form.stockItem")
                div
                  .text {{ $t('Waste.detail_TableColumn_StockOnHandQuantity') }}
                  .value {{ stockItemInfo.baseUnitQuantity }}
            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

          .Form-item.required
            label.Form-item-label
              | {{ $t('DirectOrders.formFieldUnitName') }}
            .control.form-item-select
              Icon(name="icon-down-arrow")
              select.select(
              id="select-directOrders-popup-baseunit"
              name="unitId"
              :disabled="isEdit"
              v-model="form.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('DirectOrders.formFieldUnitName')"
              :class="{ 'is-danger': veeErrors.has('unitId') }")
                option(
                v-if="isEdit"
                value="-1"
                id="option-directOrders-popup-baseunit-unselected") {{ $t('DirectOrders.formFieldUnitNameUnSelected') }}
                option(
                v-for="unit in filteredUnitList"
                :key="unit.id"
                :value="unit.id"
                :id="`option-directOrders-popup-baseunit-${unit.name}`"
                ) {{ unit.name }}

            p.Form-item-help.is-danger(
            v-show="veeErrors.has('unitId')"
            ) {{ veeErrors.first('unitId') }}

        template(v-else)
          .Form-item(:class="isOnlyListUsage ? 'required' : ''")
            label.Form-item-label {{ $t('DirectOrders.formFieldList') }}
            .control
              custom-search(
              componentName="form-direct-order-list"
              name="list"
              v-model="form.list"
              ref="listInput",
              :inputClass="{ 'is-danger': veeErrors.has('list') }"
              :disabled="!form.supplier"
              :on-search="getOptionsDespatchList"
              :options="selectOptionsDespatchList"
              v-validate="{required: isOnlyListUsageCalculate}"
              label="name"
              @input="addList(form.list)"
              :searchAll="true"
              :placeholder="$t('DirectOrders.formFieldListPlaceholder')"
              )
            showValidateError(
              v-show="veeErrors.has('list')"
              :errorName="veeErrors.first('list')"
            )

            .selected-items
              Button(
                v-for="(item,index) in lists",
                :key="index",
                seashell,
                :id="`selected-list-item-${index}`"
                @click="removeListItem(item)"
              )
                span {{ item.name }}
                Icon.m-left-5(
                  name="icon-popup-close"
                  :id="`selected-list-item-delete-${index}`"
                )

        .Form-item(v-if="isEdit || type === 0")
          label.Form-item-label {{ $t('DirectOrders.formFieldDescription') }}
          .control
            customTextareaInput(
              v-model="form.description"
              v-validate="'max:512'"
              name="description"
              :data-vv-as="$t('DirectOrders.formFieldDescription')"
              :error="veeErrors.has('description')"
            )

          showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
          )

    template(slot="footer")
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-request-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('DirectOrders.moduleTitle') })"
        v-model="saveAndNew")

      Button(
      id="btn-request-popup-submit"
      type="submit",
      form="direct-orders-detail-form",
      primary,
      variant="full"
      size="medium"
      :isLoading="isRunAction",
      :disabled="isRunAction || (type === 1 && lists.length === 0)")
        span(v-if="isEdit") {{ $t('DirectOrders.updateText') }}
        span(v-else) {{ $t('DirectOrders.createText') }}
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import customDatePicker from '@/view/global/custom-datepicker'

export default {
  name: 'DirectOrdersDetailForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      type: 0,
      selectOptionsStock: [],
      selectOptionSupplier: [],
      selectOptionsDespatchList: [],
      selectMiniStoreList: [],
      lists: [],
      headerType: null,
      saveAndNew: true,
      form: {
        storeId: {
          id: '-1',
          name: ''
        },
        supplier: null,
        stockItem: null,
        requestDate: new Date(),
        quantity: null,
        unitId: '-1',
        description: null,
        list: null
      },
      stockItemInfo: {},
      disableDate: {
        to: new Date()
      },
      detailObject: {},
      isOnlyListUsage: false
    }
  },
  components: {
    customDatePicker
  },
  computed: {
    ...mapGetters('Stores', [
      'miniStoreList'
    ]),
    ...mapGetters('Settings', [
      'UserInfo',
      'permissions'
    ]),

    ...mapGetters('Units', [
      'BaseUnitList',
      'findUnit',
      'getRelatedUnitList'
    ]),

    isEdit () {
      return this.status === 'edit'
    },
    isRunAction () {
      return this.$wait.is(['createDirectOrderDetail', 'updateDirectOrderDetail', 'submitForm'])
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    isOnlyListUsageCalculate () {
      return this.isOnlyListUsage && this.form.list?.length === 0 && this.type === 1
    },

    filteredUnitList () {
      if (this.isEdit) {
        return [{
          id: this.detailObject.unitId,
          name: this.detailObject.unitName
        }]
      }
      if (!this.form.stockItem) return false
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(this.form.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    directSelectOption () {
      return this.selectOptionsStock.length === 1 &&
      this.selectOptionsStock[0].matchType === 2
        ? this.selectOptionsStock[0]
        : {}
    }
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem']),
    ...mapActions('Supplier', ['searchSupplier']),
    ...mapActions('ListsDespatch', ['searchDespatchList']),
    ...mapActions('Stores', ['fetchStoreByName']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Transfer', ['getStockInfo']),
    ...mapActions('DirectOrders', [
      'createDirectOrderDetail',
      'updateDirectOrderDetail',
      'fetchDirectDetailInfo',
      'fetchDirectOrderDetail',
      'fetchDirectOrderInfo'
    ]),

    ...mapMutations('DirectOrders', ['SHOW_DETAIL']),

    addList (value) {
      if (!value || this.lists.some(item => item.id === value.id)) return
      this.lists.push(value)
      this.$nextTick(() => {
        this.form.list = null
      })
    },

    async fetchStockItems () {
      const payload = {
        storeId: this.form.storeId.id,
        stockItemId: this.form.stockItem.id,
        unitId: this.form.stockItem.unitId,
        forDate: moment(new Date()).format('YYYY-MM-DD')
      }
      await this.getStockInfo(payload)
        .then(res => {
          this.stockItemInfo = res.data
        })
    },

    selectedTypes () {
      if (this.isOnlyListUsage) this.type = 1
      return [
        { text: this.$t('DirectOrders.formTypeStockItem'), value: 0 },
        { text: this.$t('DirectOrders.formTypeListe'), value: 1 }
      ]
    },

    getOptionsSupplier (search) {
      this.searchSupplier(search)
        .then(res => {
          this.selectOptionSupplier = res.data.items
        })
    },
    getOptionsStock (search) {
      this.searchStockItem({ Text: search, types: [1, 2, 4] })
        .then(res => {
          this.selectOptionsStock = res.data.items
        })
    },
    getOptionsDespatchList (search) {
      const params = {despatchListName: search, supplierIds: [this.form.supplier.id]}
      this.searchDespatchList(params)
        .then(res => {
          this.selectOptionsDespatchList = res.data.despatchListHeaderList
        })
    },

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },
    inputSelection (data) {
      if(data.length !== 0) {
        this.form.storeId.id = data.id
        this.form.storeId.name = data.name
      }
    },
    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },
    setUnit (unitId) {
      this.form.unitId = unitId
    },
    formReset () {
      this.selectOptionsStock = []
      this.selectOptionSupplier = []
      this.selectOptionsDespatchList = []
      this.selectMiniStoreList = []
      this.lists = []
      this.form = {
        supplier: this.form.supplier ? this.form.supplier : null,
        storeId: { id: this.saveAndNew ? this.form.storeId.id : '-1', name: this.saveAndNew ? this.form.storeId.name : '' },
        stockItem: null,
        requestDate: moment().hour(0).minute(0)._d,
        quantity: null,
        description: null,
        unitId: '-1',
        list: null
      }
    },
    async newDetail () {
      const payload = {
        directRequestHeaderId: this.$route.params.id,
        storeId: this.form.storeId.id,
        requestDate: moment(this.form.requestDate).format('YYYY-MM-DD')
      }
      if (this.type === 0) {
        payload.stockItemId = this.form.stockItem.id
        payload.unitId = this.form.unitId
        payload.quantity = this.form.quantity
        payload.supplierId = this.form.supplier.id
        payload.description = this.form.description
      } else {
        payload.despatchListIds = [...this.lists.map(_ => _.id)]
      }
      const res = await this.createDirectOrderDetail({ type: this.type, payload })
      const message = this.$t('DirectOrders.createSuccesMessage')
      if (res) {
        await this.fetchDirectOrderDetail({headerId: this.$route.params.id, headerType: this.headerType})
        await this.fetchDirectOrderInfo(this.$route.params.id)
        if (this.saveAndNew) {
          this.formReset()
          this.$nextTick(() => {
            if (this.type === 0) {
              this.$refs.stockInput.$refs.search.focus()
            } else if (this.type === 1) {
              this.$refs.listInput.$refs.search.focus()
            }
            this.$validator.reset()
          })
        } else {
          this.close()
        }
        this.notifyShow({ message })
      }
    },
    async editDetail () {
      const payload = {
        id: this.$route.params.detailId,
        headerId: this.$route.params.id,
        quantity: this.form.quantity,
        description: this.form.description,
        requestDate: moment(this.form.requestDate).format('YYYY-MM-DD')
      }
      const res = await this.updateDirectOrderDetail(payload)
      const message = this.$t('DirectOrders.updateSuccesMessage')
      if (res) {
        await this.fetchDirectOrderDetail({headerId: this.$route.params.id, headerType: this.headerType})
        await this.fetchDirectOrderInfo(this.$route.params.id)
        this.notifyShow({ message })
        this.close()
      }
    },
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return false
      if (this.isEdit) {
        await this.editDetail()
      } else {
        await this.newDetail()
      }
    }, 'submitForm'),
    async getFormData () {
      if (this.isEdit) {
        const res = await this.fetchDirectDetailInfo({id: this.$route.params.detailId, headerType: this.headerType})
        if (res) {
          this.detailObject = await { ...res.data.directRequestDetail }
          this.form = await { ...this.detailObject }
          const stockInfo = {
            name: this.detailObject.stockItemName,
            id: this.detailObject.stockItemId,
            unitId: this.detailObject.unitId
          }
          const supplierInfo = {
            name: this.detailObject.supplierName,
            id: this.detailObject.supplierId
          }
          this.form.storeId = { id: this.detailObject.storeId, name: this.detailObject.storeName }
          this.form.stockItem = { ...stockInfo }
          this.form.supplier = { ...supplierInfo }
        }
      } else this.form.storeId = { id: this.UserInfo.defaultStoreId }
    },
    removeListItem (item) {
      this.lists.splice(this.lists.indexOf(item), 1)
    }
  },
  watch: {
    async 'form.stockItem' (val) {
      if (!val || this.isEdit) return
      await this.setUnit(val.unitId)
    },
    lists () {
      if (this.lists.length === 0) {
        this.form.list = null
        this.selectOptionsDespatchList = []
      }
    },
    async type () {
      this.formReset()
      this.form.supplier = null
      this.form.storeId = { id: this.UserInfo.defaultStoreId }
      await this.$validator.reset()
    }
  },
  async mounted () {
    this.$wait.start('SetData')
    this.headerType = this.$route.params.headerType
    await this.getFormData()
    this.permissions.find(i => {
      if (i.permission === 221) this.isOnlyListUsage = true
    })
    this.$wait.end('SetData')
  }
}
</script>

<style lang="scss" scoped>

.Form-item-info {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  font-size: $font-size-small;

  > div {
    display: flex;
    margin-right: 20px;
    align-items: center;

    select {
      margin-bottom: -2px;
      border: 0;
      background-color: $color-gray;
    }
  }

  .text {
    color: $color-success;
    font-weight: bold;
    margin-right: 6px;
  }

  .value {
    font-weight: $font-weight-bold;
    &:not(:last-child) {
      margin-right: 1.6%
    }
  }
}
.info {
  margin-top: 10px;
  font-weight: bold;
}
.selected-items {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .btn {
    width: auto;
    height: 34px;
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
