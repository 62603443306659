import DirectOrders from '../model/directOrders'
import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import moment from 'moment'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    directOrders: new DirectOrders(),
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    approvalHistory: []
  },
  getters: {
    directOrders: state => state.directOrders.list,
    isEmptyDirectOrders: state => state.directOrders.list.length === 0,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    approvalHistory: state => state.approvalHistory
  },
  actions: {
    async fetchDirectOrderHeaderAll({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'fetchDirectOrderHeaderAll',
        methodName: 'fetchDirectOrderHeaderAll',
        method: () => {
          return HTTP.post('DirectRequest/header/all', {
            ...payload,
            PageSize: payload.pageSize,
            pageNumber: PageNumber
          })
        },
        success: result => {
          commit('SET_ALL_DIRECT_ORDERS', result.data)
          return result
        }
      })
      return results
    },

    async sendDirectOrderToApprove({ commit }, payload) {
      const results = await requestHandler({
        title: 'sendDirectOrderToApprove',
        methodName: 'sendDirectOrderToApprove',
        method: () => {
          return HTTP.put('DirectRequest/header/sendtoapprove', payload)
        },
        success: result => result
      })
      return results
    },

    async fetchDirectOrderInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchDirectOrderInfo',
        methodName: 'fetchDirectOrderInfo',
        method: () => {
          return HTTP.get('DirectRequest/header', {params: { Id }})
        },
        success: result => {
          commit('CHANGE_HEADER_DATA', result.data.directRequestHeader)
          return result
        }
      })
      return results
    },
    async createDirectOrderHeader({ commit }, payload) {
      const results = await requestHandler({
        title: 'createDirectOrderHeader',
        methodName: 'createDirectOrderHeader',
        method: () => {
          return HTTP.post('DirectRequest/header', payload)
        },
        success: result => result
      })
      return results
    },
    async showHistory({ commit }, payload) {
      const results = await requestHandler({
        title: 'showHistory',
        methodName: 'showHistory',
        method: () => {
          return HTTP.post('Approval/history', payload)
        },
        success: result => result
      })
      return results
    },
    async updateDirectOrderHeader({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateDirectOrderHeader',
        methodName: 'updateDirectOrderHeader',
        method: () => {
          return HTTP.put('DirectRequest/header', payload)
        },
        success: result => result
      })
      return results
    },
    async DeleteDirectOrderHeader({ commit }, Id) {
      const results = await requestHandler({
        title: 'DeleteDirectOrderHeader',
        methodName: 'DeleteDirectOrderHeader',
        method: () => {
          return HTTP.delete('DirectRequest/header', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async fetchDirectOrderDetail({ commit }, {headerId, headerType}) {
      const results = await requestHandler({
        title: 'fetchDirectOrderDetail',
        methodName: 'fetchDirectOrderDetail',
        method: () => {
          return HTTP.post('DirectRequest/detail/getbyheaderid', { headerId, headerType })
        },
        success: result => {
          commit('SHOW_DETAIL', { headerId: headerId, headerType: headerType, data: result.data })
          return result
        }
      })
      return results
    },
    async fetchDirectDetailInfo({ commit }, payload) {
      const results = await requestHandler({
        title: 'fetchDirectDetailInfo',
        methodName: 'fetchDirectDetailInfo',
        method: () => {
          return HTTP.get('DirectRequest/detail', { params: { id: payload.id, headerType: payload.headerType} })
        },
        success: result => result
      })
      return results
    },
    async createDirectOrderDetail({ commit }, obj) {
      let url
      if (obj.type === 0) {
        url = 'DirectRequest/detail/withstockitem'
      } else if (obj.type === 1) {
        url = 'DirectRequest/detail/withdespatchlist'
      }
      const results = await requestHandler({
        title: 'createDirectOrderDetail',
        methodName: 'createDirectOrderDetail',
        method: () => {
          return HTTP.post(url, obj.payload)
        },
        success: result => result
      })
      return results
    },
    async updateDirectOrderDetail({ commit }, item) {
      const payload = {
        id: item.id,
        headerId: item.headerId,
        quantity: item.quantity,
        requestDate: moment(item.requestDate).format('YYYY-MM-DD'),
        description: item.description
      }
      const results = await requestHandler({
        title: 'updateDirectOrderDetail',
        methodName: 'updateDirectOrderDetail',
        method: () => {
          return HTTP.put('/DirectRequest/detail/update', payload)
        },
        success: result => {
          commit('UPDATE_DETAIL_PRICE', item)
          return result
        }
      })
      return results
    },
    async DeleteDirectOrderDetail({ commit }, Id) {
      const results = await requestHandler({
        title: 'DeleteDirectOrderDetail',
        methodName: 'DeleteDirectOrderDetail',
        method: () => {
          return HTTP.delete('DirectRequest/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async makeDirectOrderFinalize({ commit }, Id) {
      const results = await requestHandler({
        title: 'makeDirectOrderFinalize',
        methodName: 'makeDirectOrderFinalize',
        method: () => {
          return HTTP.put('DirectRequest/finalize', { Id })
        },
        success: result => result
      })
      return results
    },
    async completeApprovedOrder({ commit }, id) {
      const results = await requestHandler({
        title: 'completeApprovedOrder',
        methodName: 'completeApprovedOrder',
        method: () => {
          return HTTP.put('DirectRequest/complete', id)
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_ALL_DIRECT_ORDERS(state, data) {
      state.directOrders.addItems(data.directRequestHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    UPDATE_DIRECT_REQUEST_ITEM(state, item) {
      state.directOrders.updateItem(item)
    },
     //
    // DETAIL
    //
    HIDE_DETAIL(state, directRequestHeader) {
      directRequestHeader.showDetail = false
    },

    HIDE_OTHERS_DETAILS(state, directRequestHeader) {
      const others = state.directOrders.list.filter(request => request.id !== directRequestHeader.id)
      others.forEach(otherrequest => {
        otherrequest.showDetail = false
      })
    },

    SHOW_DETAIL(state, { headerId, headerType, data }) {
      let directOrder = state.directOrders.findItem(headerId)
      if (directOrder) {
        const detailList = data.directRequestDetailList.map(item => {
          return {
            ...item,
            headerId: headerId,
            headerType: headerType,
            code: directOrder.code
          }
        })
        directOrder.addDetails(detailList)
        directOrder.showDetail = true
      }
    },

    CHANGE_HEADER_DATA(state, header) {
      state.directOrders.updateItem(header)
    },

    UPDATE_DETAIL_PRICE(state, { headerId, id, quantity }) {
      const header = state.directOrders.findItem(headerId)
      const detail = header.findDetail(id)
      detail.updatePrice(quantity)
      header.updateTotalPrice()
    }
  }
}
