var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DeleteAlert", {
    attrs: {
      item: _vm.item,
      fields: _vm.fields,
      isGetDataLoading: _vm.$wait.is("fetchDirectDetailInfo"),
      tableName:
        _vm.headerType === 0
          ? _vm.$t("DirectOrders.removeDetailFormTitle")
          : _vm.$t("DirectOrders.removeOrderDetailFormTitle"),
      loading: "DeleteDirectOrderDetail",
    },
    on: { delegateOnAlertRemoveOk: _vm.itemRemove },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }