<template lang="pug">
button.btn.show-MiniPopup
    Icon(name="icon-global-cardboard-box")
    .total-preview
      .total-preview-row.header
        span.title {{ $t('DirectOrders.TotalPreviewHeaderTitle') }}
      .total-preview-content
        .total-preview-row(v-for="item in list")
          span.total-preview-col.unit {{ item.name }}
          span.total-preview-col.quantity {{ (+item.quantity).toFixed(2) }}
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/stylesheet/config/mixin";

.total-preview {

  @include MiniPopupMixin(
    $display: none,
    $color: $color-gray,
    $width: 218px,
    $max-width: 218px,
    $triangle-direction: 'right',
    $padding-hor: 0,
    $padding-ver: 0,
    $triangle-bgc: $color-light-gray,
    $margin: 20px 0,
  );

  &-row.header {
    background-color: $color-ocean;

    .title {
      font-size: $font-size-small;
    }
  }

  &-row {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;

    .unit {
      color: $color-dark;
    }
    .quantity {
      color: $color-success;
      font-weight: $font-weight-normal;
    }
  }
  &-row:not(:last-child) {
    border-bottom: 1px solid $color-light-gray;
  }
  &-col {
    max-width: 50%;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.icon-global-cardboard-box {
  width: 32px;
  height: 32px;
  color: $color-primary;
}

.btn {
  background-color: transparent;
  padding: 0;
}
</style>
