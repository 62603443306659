import BaseClass from '@/utils/baseclass'

export default class ApprovedOrders extends BaseClass {
  addItem(item) {
    let newItem = new ApprovedOrder(item)
    this.list.push(newItem)
  }
}

export class ApprovedOrder {
  constructor(item) {
    this.id = item.id
    this.approvalStatus = item.approvalStatus
    this.code = item.code
    this.detailList = []
    this.showDetail = false
    this.currentApprovalLevel = item.currentApprovalLevel
    this.totalAmount = item.totalAmount
    this.maxApprovalLevel = item.maxApprovalLevel
  }

  addDetails(detailList) {
    this.detailList = []
    detailList.forEach(detail => {
      this.detailList.push(new ApprovedOrderDetail(detail))
    })
  }
}

export class ApprovedOrderDetail {
  constructor(detail) {
    this.id = detail.id
    this.storeId = detail.storeId
    this.storeName = detail.storeName
    this.stockItemId = detail.stockItemId
    this.stockItemName = detail.stockItemName
    this.taxId = detail.taxId
    this.unitId = detail.unitId
    this.unitName = detail.unitName
    this.quantity = detail.quantity
    this.requestQuantity = detail.requestQuantity
    this.requestDate = detail.requestDate
    this.description = detail.description
    this.itemGroupId = detail.itemGroupId
    this.itemGroupName = detail.itemGroupName
  }
}
