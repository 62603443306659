export function getRequestedApprovedOrders($t) {
  return [
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.directOrderNumber')
      },
      get title() {
        return $t('DirectOrders.directOrderNumber')
      }
    },
    {
      name: 'totalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.totalCounts')
      },
      get title() {
        return $t('DirectOrders.totalCounts')
      }
    },
    {
      name: 'approvalStatus',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Global.approvalStatus')
      },
      get title() {
        return $t('Global.approvalStatus')
      }
    },
    {
      name: 'maxApprovalLevel',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Global.approvalLevel')
      },
      get title() {
        return $t('Global.approvalLevel')
      }
    }
  ]
}
export function getRequestedApprovedOrdersDetail($t) {
  return [
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.tableStoreName')
      },
      get title() {
        return $t('DirectOrders.tableStoreName')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_stock')
      },
      get title() {
        return $t('Orders.order_detail_stock')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Orders.order_detail_quantity')
      },
      get title() {
        return $t('Orders.order_detail_quantity')
      }
    },
    {
      name: 'requestDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('ApproveOperations.detailTableDate')
      },
      get title() {
        return $t('ApproveOperations.detailTableDate')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('DirectOrders.tableUnitName')
      },
      get title() {
        return $t('DirectOrders.tableUnitName')
      }
    },
    {
      name: 'description',
      is_visible: true,
      get short_title() {
        return $t('Global.description')
      },
      get title() {
        return $t('Global.description')
      }
    },
    {
      name: 'itemGroupName',
      is_visible: true,
      get short_title() {
        return $t('Orders.table_itemGroupName')
      },
      get title() {
        return $t('Orders.table_itemGroupName')
      }
    }
  ]
}
