var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [[_vm._v(_vm._s(_vm.$t("Orders.createOrdersButton")))]],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "Form",
            { staticClass: "Form", attrs: { id: "orders-detail-form" } },
            [
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Orders.table_fieldStore"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("custom-search", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        "component-name": "search-order-request-select-store",
                        name: "selectStore",
                        "data-vv-as": _vm.$t("Orders.table_fieldStore"),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("selectStore"),
                        },
                        "on-search": _vm.getOptionsStore,
                        options: _vm.selectMiniStoreList,
                        label: "name",
                        loading: _vm.isSearchStoreLists,
                        searchAll: true,
                        withDebounce: false,
                      },
                      on: {
                        input: _vm.inputSelection,
                        resetOptions: _vm.resetOptions,
                      },
                      model: {
                        value: _vm.form.storeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "storeId", $$v)
                        },
                        expression: "form.storeId",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("selectStore"),
                          expression: "veeErrors.has('selectStore')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("selectStore") },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Orders.table_fieldDate"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("CustomDatepicker", {
                      class: { "is-danger": _vm.veeErrors.has("orderDate") },
                      attrs: {
                        id: "order-request-detail-datepicker",
                        name: "orderDate",
                        "data-vv-as": _vm.$t("Orders.table_fieldDate"),
                        pickerType: "manuel",
                        inputIconRight: "icon-datepicker-big",
                        inputIcon: "",
                        disabledStartDate: _vm.disableDate,
                      },
                      model: {
                        value: _vm.form.orderDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "orderDate", $$v)
                        },
                        expression: "form.orderDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.inventoryValidation,
                        expression: "inventoryValidation",
                      },
                    ],
                    staticClass: "Form-item-help is-danger",
                  },
                  [_vm._v(_vm._s(_vm.$t("Inventory.closedInventoryError")))]
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Despatches.detail_Popup_FormField_StockItemName")
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("custom-search", {
                      ref: "firstInput",
                      attrs: {
                        "component-name": "order-detail-stock-item",
                        name: "stockItem",
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_StockItemName"
                        ),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("stockItem"),
                        },
                        "on-search": _vm.getOptions,
                        options: _vm.selectOptions,
                        label: "name",
                        placeholder: _vm.$t(
                          "Despatches.detail_Popup_FormField_StockItemName_Placeholder"
                        ),
                        directSelectOption: _vm.directSelectOption,
                      },
                      on: { input: _vm.stockUnitSelection },
                      model: {
                        value: _vm.form.stockItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "stockItem", $$v)
                        },
                        expression: "form.stockItem",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("stockItem"),
                        expression: "veeErrors.has('stockItem')",
                      },
                    ],
                    staticClass: "Form-item-help is-danger",
                    attrs: { id: "validate-message-order-detail-stock-item" },
                  },
                  [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("ListsDespatch.detail_Popup_FormField_Quantity")
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customNumberInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                            min_value: 0,
                            max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                          },
                          expression:
                            "{decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                        },
                      ],
                      attrs: {
                        name: "quantity",
                        error: _vm.veeErrors.has("quantity"),
                        "data-vv-as": _vm.$t(
                          "ListsDespatch.detail_Popup_FormField_Quantity"
                        ),
                        id: "input-order-request-detail-quantity",
                        isDelayInput: true,
                      },
                      model: {
                        value: _vm.form.quantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "quantity", $$v)
                        },
                        expression: "form.quantity",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Despatches.detail_Popup_FormField_BaseUnit"))
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                    _c("customSelectInput", {
                      attrs: {
                        name: "unitId",
                        optionData: _vm.filteredUnitList,
                        "data-vv-as": _vm.$t(
                          "Despatches.detail_Popup_FormField_BaseUnit"
                        ),
                        error: _vm.veeErrors.has("unitId"),
                        id: "select-order-popup-baseunit",
                        optionIdName: "option-order-popup-baseunit",
                      },
                      model: {
                        value: _vm.form.unitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "unitId", $$v)
                        },
                        expression: "form.unitId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Orders.info"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customTextareaInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:128",
                          expression: "'max:128'",
                        },
                      ],
                      staticClass: "txt",
                      attrs: {
                        id: "input-order-detail-info",
                        name: "info",
                        "data-vv-as": _vm.$t("Orders.info"),
                        error: _vm.veeErrors.has("info"),
                      },
                      model: {
                        value: _vm.form.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CustomCheckbox", {
            attrs: {
              id: "checkbox-request-order-detail-save-and-new",
              label: _vm.$t("Global.formSaveAndNewText", {
                form: _vm.$t("Orders.detail_orderRequest"),
              }),
            },
            model: {
              value: _vm.saveAndNew,
              callback: function ($$v) {
                _vm.saveAndNew = $$v
              },
              expression: "saveAndNew",
            },
          }),
          _c(
            "Button",
            {
              staticClass: "m-top-15",
              attrs: {
                id: "finalize-order-request",
                primary: "",
                size: "large",
                variant: "full",
                iconName: "icon-btn-success",
                iconClass: "icon-btn-success",
                disabled: _vm.checkSubmitButton,
              },
              on: { click: _vm.onSubmitForm },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("EInvoice.saveSelection")))])]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }