<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Request.removeDetailFormTitle')",
  loading="DeleteRequestDetail",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions , mapGetters } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RequestRemove',

  data () {
    return {
      fields: [{
        name: 'storeName',
        is_visible: true,
        short_title: this.$t('Request.detailTableStoreName'),
        title: this.$t('Request.detailTableStoreName')
      },
      {
        name: 'requestDate',
        is_visible: true,
        short_title: this.$t('Request.detailTableDate'),
        title: this.$t('Request.detailTableDate')
      },
      {
        name: 'stockItemName',
        is_visible: true,
        short_title: this.$t('Request.detailTableStockItem'),
        title: this.$t('Request.detailTableStockItem')
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('Request.detailTableQuantity'),
        title: this.$t('Request.detailTableQuantity')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('Request.detailTableUnit'),
        title: this.$t('Request.detailTableUnit')
      }],
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      const item = this.$route.params.item
      this.item = {
        ...item,
        requestDate: moment(item.requestDate).format('DD.MM.YYYY')
      }
    } else {
      this.getRequestDetailByItem(this.$route.params.detailId)
        .then(res => {
          this.item = {
            ...res.data.requestDetail,
            requestDate: moment(res.data.requestDetail.requestDate).format('DD.MM.YYYY')
          }
        })
    }
  },

  computed: {
    ...mapGetters('Settings', [
      'checkPricePermission'
    ]),
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Request', [
      'DeleteRequestDetail',
      'getRequestDetail',
      'showRequestDetailAction',
      'getRequestDetailByItem',
      'getRequestHeaderByItem'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      let result = await this.DeleteRequestDetail(this.$route.params.detailId)
      if (result) {
        const message = this.$t('Request.removeRequestDetail_Notificiation_Deleted')
        const res = await this.getRequestDetail(this.$route.params.id)
        if (res) {
          this.showRequestDetailAction({ requestHeaderId: this.$route.params.id, data: res.data , permission: this.checkPricePermission('RequestMenu')})
        }
        this.getRequestHeaderByItem(this.$route.params.id)
        this.notifyShow({ message })
        this.close()
      }
    }, 'deleteForm')
  }
}
</script>

<style lang="scss">
</style>
