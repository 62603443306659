var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _c("div", [
          _vm._v(
            _vm._s(
              _vm.$t("Global.editData", {
                field: _vm.$t("Orders.moduleNameWithPrefix"),
              })
            )
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "detail" },
        [
          _vm.isFetchingHeaderInfo
            ? _c("Loading")
            : _c("div", { staticClass: "m-top-10 font-weight-bold" }, [
                _c("span", [_vm._v(_vm._s(_vm.form.orderNumber))]),
              ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isFetchingHeaderInfo
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "approved-order-form-header" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item" }, [
                    _c("div", { staticClass: "Form-item-info-multiple" }, [
                      _c(
                        "div",
                        { staticClass: "Form-item-info-multiple-row" },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("Orders.order_header_supplier"))
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.form.supplier.name)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "Form-item-info-multiple-row" },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("Orders.order_header_date"))),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.form.orderDate,
                                  "YYYY-MM-DD"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "Form-item-info-multiple-row" },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("Orders.order_header_store"))),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.form.store.name)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "Form-item-info-multiple-row" },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("Orders.order_header_totalAmount"))
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.form.netTotalAmount)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "Form-item-info-multiple-row" },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("Orders.order_header_grossAmount"))
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.form.grossTotalAmount)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "Form-item-info-multiple-row" },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("Orders.order_header_taxAmount"))
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.form.taxTotalAmount)),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Orders.order_header_date"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("CustomDatepicker", {
                          class: {
                            "is-danger": _vm.veeErrors.has(`orderDate`),
                          },
                          attrs: {
                            name: "orderDate",
                            "data-vv-as": _vm.$t("Orders.order_header_date"),
                            id: "datepicker-approved-order-header-edit",
                            pickerType: "manuel",
                            inputIconRight: "icon-datepicker-big",
                            inputIcon: "",
                            disabledStartDate: _vm.disableDate,
                          },
                          model: {
                            value: _vm.form.orderDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "orderDate", $$v)
                            },
                            expression: "form.orderDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("orderDate"),
                            expression: "veeErrors.has('orderDate')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.has("orderDate")))]
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Waste.form_description"))),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.description,
                            expression: "form.description",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:512",
                            expression: "'max:512'",
                          },
                        ],
                        staticClass: "txt",
                        class: {
                          "is-danger": _vm.veeErrors.has("description"),
                        },
                        attrs: {
                          name: "description",
                          "data-vv-as": _vm.$t("Waste.form_description"),
                          id: "textarea-approved-order-header-edit",
                          rows: "5",
                          type: "text",
                        },
                        domProps: { value: _vm.form.description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("description"),
                            expression: "veeErrors.has('description')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("description")))]
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "button-approved-order-form-header-action",
                form: "approved-order-form-header",
                disabled: _vm.isRunAction,
                isLoading: _vm.isRunAction,
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("Global.editData", {
                      field: _vm.$t("Orders.moduleNameWithPrefix"),
                    })
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }