export function getRequestHeaderFields($t) {
  return [
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Request.table_Header_field_RNumber')
      },
      get title() {
        return $t('Request.table_Header_field_RNumber')
      }
    },
    {
      name: 'orderStatus',
      type: 'status',
      is_visible: true,
      get short_title() {
        return $t('Request.table_Header_field_RStatus')
      },
      get title() {
        return $t('Request.table_Header_field_RStatus')
      },
      getStatusText: function(row) {
        switch (row.orderStatus) {
          case 0:
            if (row.status === 1) return $t('Request.table_Header_field_RStatusWait')
            else if (row.status === 5) return $t('Request.table_Header_field_StatusWaitingForApproval')
            else if (row.status === 6) return $t('Request.table_Header_field_StatusRejected')
            else return $t('Request.table_Header_field_RStatusSaved')
          case 1:
            return $t('Request.table_Header_field_RStatusWaitOrder')
          case 2:
            return $t('Request.table_Header_field_RStatusPartiallyOrder')
          case 3:
            return $t('Request.table_Header_field_RStatusAllOrder')
          case 4:
            return $t('Request.table_Header_field_RStatusWaitTransfer')
          case 5:
            return $t('Request.table_Header_field_RStatusPartiallyTransfer')
          case 6:
            return $t('Request.table_Header_field_RStatusAllTransfer')
          default:
            return '-'
        }
      }
    },
    {
      name: 'requestType',
      type: 'status',
      is_visible: true,

      get short_title() {
        return $t('Request.table_Header_field_RType')
      },
      get title() {
        return $t('Request.table_Header_field_RType')
      },

      getStatusText: function(row) {
        switch (row.requestType) {
          case 1:
            return $t('Request.type_order')
          case 2:
            return $t('Request.type_order')
          case 3:
            return $t('Request.type_transfer')
          default:
            return ''
        }
      }
    },

    {
      name: 'modifiedOn',
      is_visible: true,
      get short_title() {
        return $t('Request.table_Header_modified_Date')
      },
      get title() {
        return $t('Request.table_Header_modified_Date')
      }
    },
    {
      name: 'modifiedBy',
      is_visible: true,
      get short_title() {
        return $t('Request.table_Header_modified_By')
      },
      get title() {
        return $t('Request.table_Header_modified_By')
      }
    },
    {
      name: 'totalAmount',
      is_visible: true,
      get short_title() {
        return $t('Request.table_Header_totalAmount')
      },
      get title() {
        return $t('Request.table_Header_totalAmount')
      }
    },
    {
      name: 'reasons',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    },

    {
      name: 'wmsStatus',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}
export function getRequestDetailFields(i18n, request, requestMenuPermission) {
  const isTransferType = request && request.requestType === 3
  return [
    {
      type: isTransferType ? 'fixed' : '',
      name: 'fromStoreName',
      is_visible: isTransferType,
      get short_title() {
        return i18n.t('Dictionary.fromStore')
      },
      get title() {
        return i18n.t('Dictionary.fromStore')
      }
    },
    {
      type: !isTransferType ? 'fixed' : '',
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return isTransferType ? i18n.t('Dictionary.toStore') : i18n.t('Request.detailTableStoreName')
      },
      get title() {
        return isTransferType ? i18n.t('Dictionary.toStore') : i18n.t('Request.detailTableStoreName')
      }
    },
    {
      name: 'requestDate',
      type: 'date',
      editable: true,
      usableDate: {
        from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
        to: new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split('T')[0]
      },
      is_visible: true,
      get short_title() {
        return i18n.t('Request.detailTableDate')
      },
      get title() {
        return i18n.t('Request.detailTableDate')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return i18n.t('Request.detailTableStockItem')
      },
      get title() {
        return i18n.t('Request.detailTableStockItem')
      }
    },
    {
      type: 'quantity',
      name: 'recommendedQuantity',
      is_visible: true,
      get short_title() {
        return i18n.t('Request.recommendedQuantity')
      },
      get title() {
        return i18n.t('Request.recommendedQuantity')
      }
    },
    {
      type: 'quantity',
      name: 'quantity',
      editable: true,
      validationRules: {min_value: 0, max: 15},
      is_visible: true,
      get short_title() {
        return i18n.t('Request.detailTableQuantity')
      },
      get title() {
        return i18n.t('Request.detailTableQuantity')
      }
    },
    {
      type: 'quantity',
      name: isTransferType ? 'transferredQuantity' : 'orderedQuantity',
      is_visible: true,
      get short_title() {
        return isTransferType ? i18n.t('Request.table_transferredQuantity') : i18n.t('Request.table_orderedQuantity')
      },
      get title() {
        return isTransferType ? i18n.t('Request.table_transferredQuantity') : i18n.t('Request.table_orderedQuantity')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return i18n.t('Request.detailTableUnit')
      },
      get title() {
        return i18n.t('Request.detailTableUnit')
      }
    },
    {
      name: 'baseUnitPrice',
      is_visible: requestMenuPermission,
      get short_title() {
        return i18n.t('Request.detailTableBaseUnitPrice')
      },
      get title() {
        return i18n.t('Request.detailTableBaseUnitPrice')
      }
    },
    {
      name: 'amount',
      is_visible: requestMenuPermission,
      get short_title() {
        return i18n.t('Request.detailTableAmount')
      },
      get title() {
        return i18n.t('Request.detailTableAmount')
      }
    },
    {
      name: 'stockOnHandQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return i18n.t('Request.stockOnHandQuantity')
      },
      get title() {
        return i18n.t('Request.stockOnHandQuantity')
      }
    },
    {
      name: 'minimumStoreItemQuantity',
      is_visible: true,
      type: 'quantity',
      get short_title() {
        return i18n.t('Request.storeItemTypeMin')
      },
      get title() {
        return i18n.t('Request.storeItemTypeMin')
      }
    },
    {
      name: 'optimumStoreItemQuantity',
      is_visible: true,
      type: 'quantity',
      get short_title() {
        return i18n.t('Request.storeItemTypeOpt')
      },
      get title() {
        return i18n.t('Request.storeItemTypeOpt')
      }
    },
    {
      name: 'requestItemStatus',
      type: 'status',
      is_visible: true,
      get short_title() {
        return i18n.t('Request.detailTableRStatus')
      },
      get title() {
        return i18n.t('Request.detailTableRStatus')
      },
      getStatusText: (row) => {
        switch (row.state) {
          case 1:
            return i18n.t('Request.detailItemStatusFalse')
          case 2:
            return i18n.t('Request.detailItemStatusPassive')
          case 3:
            return i18n.t('Request.detailItemStatusPartiallyOrdered')
          case 4:
            return i18n.t('Request.detailItemStatusOrdered')
          case 5:
            return i18n.t('Request.detailItemStatusPartiallyTransfered')
          case 6:
            return i18n.t('Request.detailItemStatusTransfered')
          default:
            return '-'
        }
      }
    },

    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}
