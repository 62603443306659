var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("Global.editData", {
                    field: _vm.$t("DirectOrders.moduleTitleWithPrefix"),
                  })
                )
              ),
            ])
          : _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("Global.addData", {
                    field: _vm.$t("DirectOrders.moduleTitle"),
                  })
                )
              ),
            ]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "direct-orders-header-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "Form-item" },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("DirectOrders.formFieldDescription"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customTextareaInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:512",
                          expression: "'max:512'",
                        },
                      ],
                      attrs: {
                        id: "direct-orders-header-form-description",
                        name: "description",
                        "data-vv-as": _vm.$t(
                          "DirectOrders.formFieldDescription"
                        ),
                        error: _vm.veeErrors.has("description"),
                      },
                      model: {
                        value: _vm.form.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description",
                      },
                    }),
                  ],
                  1
                ),
                _c("showValidateError", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("description"),
                      expression: "veeErrors.has('description')",
                    },
                  ],
                  attrs: { errorName: _vm.veeErrors.first("description") },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                id: "direct-orders-header-form-action-button",
                primary: "",
                type: "submit",
                size: "large",
                variant: "full",
                form: "direct-orders-header-form",
                isLoading: _vm.isRunAction,
                disabled: _vm.isRunAction,
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.editData", {
                          field: _vm.$t("DirectOrders.moduleTitleWithPrefix"),
                        })
                      )
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.addData", {
                          field: _vm.$t("DirectOrders.moduleTitle"),
                        })
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }