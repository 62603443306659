var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("Global.editDataDetail", {
                    field: _vm.$t("Orders.moduleNameWithPrefix"),
                  })
                )
              ),
            ])
          : _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("Global.addDataDetail", {
                    field: _vm.$t("Orders.moduleName"),
                  })
                )
              ),
            ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isSettingUpdateData
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "approved-order-detail-form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Dictionary.StockItem"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "firstField",
                          attrs: {
                            name: "stockItem",
                            "component-name":
                              "form-approved-order-detail-stock-item",
                            "data-vv-as": _vm.$t("Dictionary.StockItem"),
                            inputClass: {
                              "is-danger": _vm.veeErrors.has("stockItem"),
                            },
                            "on-search": _vm.getOptions,
                            options: _vm.selectOptions,
                            disabled: _vm.isEdit,
                            label: "name",
                            placeholder: _vm.$t("Global.search_Placeholder"),
                            directSelectOption: _vm.directSelectOption,
                          },
                          model: {
                            value: _vm.form.stockItem,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "stockItem", $$v)
                            },
                            expression: "form.stockItem",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("stockItem"),
                            expression: "veeErrors.has('stockItem')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
                    ),
                  ]),
                  _vm.form.stockItem
                    ? _c("div", { staticClass: "Form-item-info" }, [
                        _c("div", [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Despatches.detail_Popup_FormField_StockItemName_InfoBaseUnit"
                                )
                              )
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.form.stockItem.baseUnitName)),
                          ]),
                        ]),
                        _c("div", [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Despatches.detail_Popup_FormField_StockItemName_InfoTax"
                                )
                              )
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|is_not:-1",
                                    expression: "'required|is_not:-1'",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.taxId,
                                    expression: "form.taxId",
                                  },
                                ],
                                staticClass: "txt tax",
                                class: {
                                  "is-danger": _vm.veeErrors.has("taxId"),
                                },
                                attrs: {
                                  name: "taxId",
                                  "data-vv-as": _vm.$t(
                                    "Despatches.detail_Popup_FormField_Tax"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.form,
                                      "taxId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "-1",
                                      id: "option-despatch-popup-unselected",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Despatches.detail_Popup_FormField_TaxId_Unselected"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._l(_vm.Taxes, function (Tax) {
                                  return _c(
                                    "option",
                                    {
                                      key: Tax.id,
                                      attrs: {
                                        id: `option-despatch-popup-${Tax.id}`,
                                      },
                                      domProps: { value: Tax.id },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          "%" + Tax.ratio + " - " + Tax.name
                                        )
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("taxId"),
                            expression: "veeErrors.has('taxId')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("taxId")))]
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_Popup_FormField_Quantity")
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.form.quantity,
                            expression: "form.quantity",
                            modifiers: { number: true },
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required: true,
                              decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                              min_value: 0,
                              multiplyTwoField: _vm.form.price,
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            },
                            expression:
                              "{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), min_value:0, multiplyTwoField: form.price, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                          },
                        ],
                        staticClass: "txt no-spinners",
                        class: { "is-danger": _vm.veeErrors.has("quantity") },
                        attrs: {
                          name: "quantity",
                          id: "approved-order-form-quantity",
                          autocomplete: "off",
                          "data-vv-as": _vm.$t(
                            "Despatches.detail_Popup_FormField_Quantity"
                          ),
                          type: "number",
                          step: "any",
                        },
                        domProps: { value: _vm.form.quantity },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "quantity",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("quantity"),
                            expression: "veeErrors.has('quantity')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("quantity")))]
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_Popup_FormField_BaseUnit")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.unitId,
                                expression: "form.unitId",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                            ],
                            staticClass: "select",
                            class: { "is-danger": _vm.veeErrors.has("unitId") },
                            attrs: {
                              id: "select-despatch-popup-baseunit",
                              name: "unitId",
                              "data-vv-as": _vm.$t(
                                "Despatches.detail_Popup_FormField_BaseUnit"
                              ),
                              disabled: _vm.isEdit,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  "unitId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            !_vm.isEdit
                              ? _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "-1",
                                      id: "option-orders-detail-popup-baseunit-unselected",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Despatches.detail_Popup_FormField_BaseUnit_Unselected"
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._l(_vm.filteredUnitList, function (unit) {
                              return _c(
                                "option",
                                {
                                  key: unit.id,
                                  attrs: {
                                    id: `option-orders-detail-popup-baseunit-${unit.name}`,
                                  },
                                  domProps: { value: unit.id },
                                },
                                [_vm._v(_vm._s(unit.name))]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("unitId"),
                            expression: "veeErrors.has('unitId')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("unitId")))]
                    ),
                  ]),
                  !_vm.headerInfo.supplierIsZeroPriceAvailable
                    ? [
                        _c("div", { staticClass: "Form-item required" }, [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Despatches.detail_Popup_FormField_NetUnitPrice"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("currency-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: true,
                                      greater_than: 0,
                                      multiplyTwoField: _vm.form.quantity,
                                    },
                                    expression:
                                      "{required: true, greater_than:0, multiplyTwoField: form.quantity}",
                                  },
                                ],
                                staticClass: "txt",
                                class: {
                                  "is-danger": _vm.veeErrors.has("unitPrice"),
                                },
                                attrs: {
                                  name: "unitPrice",
                                  "data-vv-as": _vm.$t(
                                    "Despatches.detail_Popup_FormField_NetUnitPrice"
                                  ),
                                  id: "despatches-detail-unit-price",
                                },
                                model: {
                                  value: _vm.form.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "price", $$v)
                                  },
                                  expression: "form.price",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("unitPrice"),
                                  expression: "veeErrors.has('unitPrice')",
                                },
                              ],
                              staticClass: "Form-item-help is-danger",
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("unitPrice")))]
                          ),
                        ]),
                        _c("div", { staticClass: "Form-item flexbox" }, [
                          _c("div", { staticClass: "item m-right-10" }, [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_Popup_FormField_Discount1"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:-1|max_value:100",
                                      expression:
                                        "'greater_than:-1|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    id: "despatches-detail-discount1",
                                    name: "discount1",
                                    "data-vv-as": _vm.$t(
                                      "Despatches.detail_Popup_FormField_Discount1"
                                    ),
                                    placeholder: _vm.$t(
                                      "Despatches.popupForm_Field_Discount1_Placeholder"
                                    ),
                                    error: _vm.veeErrors.has("discount1"),
                                  },
                                  model: {
                                    value: _vm.form.discount1,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "discount1", $$v)
                                    },
                                    expression: "form.discount1",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has("discount1"),
                                      expression: "veeErrors.has('discount1')",
                                    },
                                  ],
                                  attrs: {
                                    errorName: _vm.veeErrors.first("discount1"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Despatches.detail_Popup_FormField_Discount2"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:-1|max_value:100",
                                      expression:
                                        "'greater_than:-1|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    disabled: +_vm.form.discount1 <= 0,
                                    id: "despatches-detail-discount2",
                                    name: "discount2",
                                    "data-vv-as": _vm.$t(
                                      "Despatches.detail_Popup_FormField_Discount2"
                                    ),
                                    placeholder: _vm.$t(
                                      "Despatches.popupForm_Field_Discount2_Placeholder"
                                    ),
                                    error: _vm.veeErrors.has("discount2"),
                                  },
                                  model: {
                                    value: _vm.form.discount2,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "discount2", $$v)
                                    },
                                    expression: "form.discount2",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has("discount2"),
                                      expression: "veeErrors.has('discount2')",
                                    },
                                  ],
                                  attrs: {
                                    errorName: _vm.veeErrors.first("discount2"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Waste.form_description"))),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.description,
                            expression: "form.description",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:512",
                            expression: "'max:512'",
                          },
                        ],
                        staticClass: "txt",
                        class: {
                          "is-danger": _vm.veeErrors.has("description"),
                        },
                        attrs: {
                          name: "description",
                          "data-vv-as": _vm.$t("Waste.form_description"),
                          rows: "5",
                          type: "text",
                        },
                        domProps: { value: _vm.form.description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("description"),
                            expression: "veeErrors.has('description')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("description")))]
                    ),
                  ]),
                ],
                2
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                id: "approved-order-detail-form-action-button",
                type: "submit",
                variant: "full",
                form: "approved-order-detail-form",
                primary: "",
                size: "medium",
                isLoading: _vm.isRunAction,
                disabled: _vm.isRunAction,
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.editDataDetail", {
                          field: _vm.$t("Orders.moduleNameWithPrefix"),
                        })
                      )
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.addDataDetail", {
                          field: _vm.$t("Orders.moduleName"),
                        })
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }