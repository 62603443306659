var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [_vm._v(_vm._s(_vm.$t("Request.updateRequestDetail")))])
          : _c("div", [_vm._v(_vm._s(_vm.$t("Request.Request_form_detail")))]),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "info" }, [
          _c("span", [_vm._v(_vm._s(_vm.code))]),
        ]),
      ]),
      _c("template", { slot: "content" }, [
        !_vm.isEdit
          ? _c(
              "div",
              { staticClass: "selectType" },
              [
                _c("SelectedButtons", {
                  attrs: { data: _vm.requestTypeOptions() },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-request-detail" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitRequestDetail.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "border-bottom-gray m-bottom-25" }, [
              _c(
                "p",
                {
                  staticClass: "c-light p-bottom-5 p-top-5",
                  attrs: { id: "request-detail-popup-form-request-type" },
                },
                [_vm._v(_vm._s(_vm.$t("Request.table_Header_field_RType")))]
              ),
              _c(
                "p",
                {
                  staticClass: "c-success font-size-bigger m-bottom-5",
                  attrs: { id: "request-detail-popup-form-request-type-text" },
                },
                [_vm._v(_vm._s(_vm.requestTypeText))]
              ),
            ]),
            _vm.type === 2
              ? _c(
                  "div",
                  {
                    staticClass: "Form-item required",
                    attrs: { id: "request-detail-popup-form-min-opt-item" },
                  },
                  [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Request.detail-popup-min-opt-item-label")
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control flexbox wrap" },
                      [
                        _c("CustomRadio", {
                          attrs: {
                            id: `request-detail-popup-min-selection`,
                            label: _vm.$t(
                              "Request.detail-popup-min-select-label"
                            ),
                            checked: _vm.isMinStock,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeMinOptType(true)
                            },
                          },
                        }),
                        _c("CustomRadio", {
                          staticClass: "m-top-10",
                          attrs: {
                            id: `request-detail-popup-opt-selection`,
                            label: _vm.$t(
                              "Request.detail-popup-opt-select-label"
                            ),
                            checked: !_vm.isMinStock,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeMinOptType(false)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "Form-item required",
                attrs: { id: "request-detail-popup-form-request-form" },
              },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm.isTransferType
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("Dictionary.toStore")))])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("Request.Form_store"))),
                      ]),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("custom-search", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|is_not:-1",
                          expression: "'required|is_not:-1'",
                        },
                      ],
                      ref: "firstInput",
                      attrs: {
                        "component-name": "select-request-store-name",
                        name: "storeName",
                        "data-vv-as": _vm.isTransferType
                          ? _vm.$t("Dictionary.toStore")
                          : _vm.$t("Request.Form_store"),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("storeName"),
                        },
                        "on-search": _vm.getOptionsStore,
                        options: _vm.selectMiniStoreList,
                        disabled: _vm.isEdit || _vm.isStoreDisabled,
                        label: "name",
                        searchAll: true,
                        withDebounce: false,
                        placeholder: _vm.$t(
                          "Request.detail_Popup_FormField_StoreName_Unselected"
                        ),
                        loading: _vm.isSearchStoreLists,
                      },
                      on: {
                        input: _vm.inputSelection,
                        resetOptions: _vm.resetOptions,
                      },
                      model: {
                        value: _vm.form.storeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "storeId", $$v)
                        },
                        expression: "form.storeId",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("storeName"),
                          expression: "veeErrors.has('storeName')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("storeName") },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm.isTransferType
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Dictionary.fromStore"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control form-item-select" },
                    [
                      _c("custom-search", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|is_not:-1",
                            expression: "'required|is_not:-1'",
                          },
                        ],
                        ref: "fromStoreInput",
                        attrs: {
                          "component-name": "select-request-from-store-name",
                          name: "fromStoreName",
                          "data-vv-as": _vm.$t("Dictionary.fromStore"),
                          inputClass: {
                            "is-danger": _vm.veeErrors.has("fromStoreName"),
                          },
                          "on-search": _vm.getOptionsFromStore,
                          options: _vm.selectMiniFromStoreList,
                          disabled: _vm.isEdit || _vm.isStoreDisabled,
                          label: "name",
                          searchAll: true,
                          withDebounce: false,
                          placeholder: _vm.$t(
                            "Request.detail_Popup_FormField_StoreName_Unselected"
                          ),
                          loading: _vm.isSearchStoreLists,
                        },
                        on: {
                          input: _vm.inputFromSelection,
                          resetOptions: _vm.resetOptions,
                        },
                        model: {
                          value: _vm.form.fromStoreId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fromStoreId", $$v)
                          },
                          expression: "form.fromStoreId",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("fromStoreName"),
                            expression: "veeErrors.has('fromStoreName')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("fromStoreName"),
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "Form-item required" },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Request.form_RequestDate"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control form-item-select" },
                  [
                    _c("CustomDatepicker", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      class: { "is-danger": _vm.veeErrors.has(`RequestDate`) },
                      attrs: {
                        id: "request-form-rdate",
                        name: "RequestDate",
                        "data-vv-as": _vm.$t("Request.form_RequestDate"),
                        pickerType: "manuel",
                        inputIconRight: "icon-datepicker-big",
                        inputIcon: "",
                        position: "right",
                        disabledStartDate: _vm.disableDate,
                      },
                      model: {
                        value: _vm.form.requestDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "requestDate", $$v)
                        },
                        expression: "form.requestDate",
                      },
                    }),
                  ],
                  1
                ),
                _c("showValidateError", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("RequestDate"),
                      expression: "veeErrors.has('RequestDate')",
                    },
                  ],
                  attrs: { errorName: _vm.veeErrors.first("RequestDate") },
                }),
              ],
              1
            ),
            _vm.type === 0 || _vm.isEdit
              ? [
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Request.Form_StockItemName"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "focusableInput",
                            attrs: {
                              name: "stockItem",
                              "component-name":
                                "form-request-detail-stock-item",
                              "data-vv-as": _vm.$t(
                                "Request.Form_StockItemName"
                              ),
                              disabled: _vm.isEdit,
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("stockItem"),
                              },
                              "on-search": _vm.getOptions,
                              options: _vm.selectOptions,
                              label: "name",
                              placeholder: _vm.$t(
                                "Request.Form_StockItemName_Placeholder"
                              ),
                              directSelectOption: _vm.directSelectOption,
                            },
                            on: { input: _vm.stockUnitSelection },
                            model: {
                              value: _vm.form.stockItem,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "stockItem", $$v)
                              },
                              expression: "form.stockItem",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("stockItem"),
                            expression: "veeErrors.has('stockItem')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("stockItem") },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item required row-field" }, [
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("Request.Form_Quantity"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("customNumberInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    required: true,
                                    greater_than: 0,
                                    max: _vm.$getConst(
                                      "MAX_DIGITS_FOR_QUANTITY"
                                    ),
                                  },
                                  expression:
                                    "{required: true, greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                                },
                              ],
                              attrs: {
                                id: "request-form-quantity",
                                name: "quantity",
                                "data-vv-as": _vm.$t("Dictionary.Quantity"),
                                error: _vm.veeErrors.has("quantity"),
                              },
                              model: {
                                value: _vm.form.quantity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "quantity", $$v)
                                },
                                expression: "form.quantity",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("quantity"),
                              expression: "veeErrors.has('quantity')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("quantity") },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("Request.Form_unitPrice"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("customNumberInput", {
                              attrs: {
                                id: "request-form-unit-price",
                                name: "unitPrice",
                                "data-vv-as": _vm.$t("Dictionary.baseQuantity"),
                                disabled: true,
                                error: _vm.veeErrors.has("unitPrice"),
                              },
                              model: {
                                value: _vm.form.unitPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "unitPrice", $$v)
                                },
                                expression: "form.unitPrice",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("unitPrice"),
                              expression: "veeErrors.has('unitPrice')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("unitPrice"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Request.Form_BaseUnit"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                          _c("customSelectInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                            ],
                            attrs: {
                              name: "unitId",
                              optionData: _vm.filteredUnitList,
                              "data-vv-as": _vm.$t("Request.Form_BaseUnit"),
                              error: _vm.veeErrors.has("unitId"),
                              id: "select-request-popup-baseunit",
                              optionIdName: "option-request-popup-baseunit",
                              disabled: _vm.isEdit,
                            },
                            model: {
                              value: _vm.form.unitId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "unitId", $$v)
                              },
                              expression: "form.unitId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("unitId"),
                            expression: "veeErrors.has('unitId')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("unitId") },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "Form-item" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Request.formFieldDescription"))),
                      ]),
                      _c("div", { staticClass: "control" }),
                      _c("customTextareaInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:128",
                            expression: "'max:128'",
                          },
                        ],
                        attrs: {
                          id: "request-form-description",
                          name: "description",
                          "data-vv-as": _vm.$t("Request.formFieldDescription"),
                          error: _vm.veeErrors.has("description"),
                          row: "3",
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("description"),
                            expression: "veeErrors.has('description')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("description"),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm.type === 1
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "Form-item",
                      class: _vm.isOnlyListUsage ? "required" : "",
                    },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Request.Form_List"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: _vm.isOnlyListUsageCalculate,
                                },
                                expression:
                                  "{required: isOnlyListUsageCalculate}",
                              },
                            ],
                            ref: "focusableInput",
                            class: { "is-danger": _vm.veeErrors.has("list") },
                            attrs: {
                              name: "list",
                              "component-name": "request-form-list",
                              "data-vv-as": _vm.$t("Request.Form_List"),
                              "on-search": _vm.getOptionsRequestList,
                              searchAll: true,
                              options: _vm.selectOptionsRList,
                              label: "name",
                              placeholder: _vm.$t(
                                "Request.Form_List_Placeholder"
                              ),
                            },
                            model: {
                              value: _vm.form.list,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "list", $$v)
                              },
                              expression: "form.list",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("list"),
                            expression: "veeErrors.has('list')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("list") },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "selected-items" },
                    _vm._l(_vm.lists, function (item, index) {
                      return _c(
                        "Button",
                        {
                          key: index,
                          attrs: {
                            seashell: "",
                            id: `selected-stock-item-${index}`,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeListItem(item)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.name))]),
                          _c("Icon", {
                            staticClass: "m-left-5",
                            attrs: {
                              name: "icon-popup-close",
                              id: `selected-stock-item-delete-${index}`,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              : [
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Request.AllGroupList"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("CustomMultiselect", {
                          staticClass: "toolbar-filters__item m-top-10",
                          attrs: {
                            id: "request-store-filter",
                            items: _vm.groupList,
                            selected: _vm.groupFilter,
                            unselectedText: _vm.$t(
                              "Request.toolbar_Stores_Unselected"
                            ),
                            popupHeader: _vm.$t("Request.AllGroupList"),
                            allLabel: _vm.$t("Request.toolbar_Stores_AllLabel"),
                            buttonText: _vm.$t(
                              "Request.toolbar_Stores_ButtonText"
                            ),
                            buttonIconLeft: "btn-success",
                          },
                          on: {
                            delegateCheckAll: _vm.onChangeGroupFilter,
                            delegateCheckItem: _vm.onChangeGroupFilter,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
          ],
          2
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-request-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Request.Request_form_detail_name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              staticClass: "item",
              attrs: {
                id: "btn-request-popup-submit",
                size: "medium",
                primary: "",
                variant: "full",
                type: "submit",
                form: "form-request-detail",
                disabled:
                  _vm.isLoading ||
                  (_vm.type === 1 && _vm.lists.length === 0) ||
                  _vm.veeErrors.has("description"),
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Request.updateRequestDetail")))]
                    : [_vm._v(_vm._s(_vm.$t("Request.addRequestDetail")))],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }